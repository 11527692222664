import React from "react";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import InputStyles from "../../../assets/jss/components/InputStyles";
import Search from "../Search";
import AutocompleteInput from "./Fields/AutocompleteInput";

const Inputs = (props) => {
  const {
    classes,
    type,
    searchValue,
    handleClick,
    handleChange,
    options,
    setSearchValue,
    placeholder,
    clearSearch,
    desktopFilter,
    setFilterOpen,
    filterOpen,
  } = props;

  const handleClearSearch = () => {
    setSearchValue("");
    if (clearSearch) {
      clearSearch();
    }
  };

  if (type === "autocomplete") {
    return (
      <AutocompleteInput
        searchValue={searchValue}
        handleClick={handleClick}
        options={options.map((option) => option.name)}
        placeholder={placeholder}
        handleChange={handleChange}
      />
    );
  }
  if (type === "searchBar") {
    return (
      <Search
        searchValue={searchValue}
        placeholder={placeholder}
        handleChange={handleChange}
        handleClearSearch={handleClearSearch}
        desktopFilter={desktopFilter}
        setFilterOpen={setFilterOpen}
        filterOpen={filterOpen}
      />
    );
  }
};

export default withStyles(InputStyles)(Inputs);
