import moment from "moment/moment";

const utils = {
  formatDate: (date) => {
    return moment.utc(date).format("L");
  },
  formatTimeDuration: (object) => {
    let now = moment(new Date());
    let date = moment(object.created);
    let duration = moment.duration(now.diff(date))._data;
    let text, number;
    if (duration.years > 0) {
      number = duration.years;
      text = "Year";
    } else if (duration.months > 0) {
      text = "Month";
      number = duration.months;
    } else if (duration.days > 0) {
      text = "Day";
      number = duration.days;
    } else if (duration.minutes > 0) {
      text = "Minute";
      number = duration.minutes;
    } else {
      text = "Second";
      number = duration.seconds;
    }
    return `${number} ${text}${number === 1 ? "" : "s"} Ago`;
  },
  formatDateTime: (date) => {
    return moment(date).format("L HH:mm");
  },
  formatHeight: (height) => {
    let feet = Math.floor(height / 12);
    let inches = height - feet * 12;
    return `${feet}'${inches}"`;
  },
  formatBMI: (details) => {
    let formula = (703 * details.weight) / (details.height * details.height);
    return Math.round(formula * 100) / 100;
  },
  capitalizeString: (string) => {
    string = string.toLowerCase().split(" ");
    for (let i = 0; i < string.length; i++) {
      string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
    }
    return string.join(" ");
  },
  removeUnderscores: (string) => {
    return string.split("_").join(" ");
  },
  removeUnderscoresTitleCase: (string) => {
    let s = string
      .split("_")
      .join(" ")
      .split("-")
      .join(" ")
      .toLowerCase()
      .split(" ");
    for (let i = 0; i < s.length; i++) {
      s[i] = s[i][0].toUpperCase() + s[i].slice(1);
    }
    return s.join(" ");
  },
  splitCamelcaseString: (string) => {
    return string.replace(/([a-z0-9])([A-Z])/g, "$1 $2");
  },
  camelToSnake: (str) =>
    str.replace(
      /([a-z][A-Z])/g,
      (group) =>
        `${group.toLowerCase().substring(0, 1)}-${group
          .toLowerCase()
          .substring(1)}`
    ),
  snakeToCamel: (str) =>
    str.replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", "").replace("_", "")
    ),
  rgbToHex: (a) => {
    a = a.replace(/[^\d,]/g, "").split(",");
    return (
      "#" +
      ((1 << 24) + (+a[0] << 16) + (+a[1] << 8) + +a[2]).toString(16).slice(1)
    );
  },
  toUserFriendlySize: (bytes) => {
    let i = Math.floor(Math.log(bytes) / Math.log(1024)),
      sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + " " + sizes[i];
  },
  sortAlphabetically: (items, sortBy) => {
    items.sort((a, b) => a[sortBy].localeCompare(b[sortBy]));
    return items;
  },
  calculateAge: (birthday) => {
    if (birthday) {
      return moment().diff(moment(birthday), "years");
    }
  },
};

export default utils;
