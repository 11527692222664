const RecordsStyles = (theme) => ({
  backIcon: {
    zIndex: "9999999",
    position: "absolute",
    top: "8px",
    left: "10px",
  },
  mainContainer: {
    padding: "0 5vw",
    overflow: "auto",
    height: "91vh",
  },
  userCard: {
    textAlign: "center",
    "& .MuiCardContent-root": {
      padding: "10px",
    },
  },
  recordLocationDetails: {
    textAlign: "center",
    marginTop: "10px",
    "& h5": {
      fontWeight: "bold",
      color: "#0b0b0b",
      fontSize: "5.25vw",
    },
    "& h4": {
      color: "#3B3B3B",
      fontWeight: "600",
      fontSize: "4.5vw",
    },
    "& .MuiGrid-container": {
      marginTop: "5px",
      textAlign: "center",
    },
  },
  header: {
    marginTop: "25px",
    "& p": {
      fontWeight: "bold",
    },
  },
  reasonForVisit: {
    marginTop: "10px",
    "& .MuiCard-root": {
      padding: "5px 15px",
      marginBottom: "5px",
    },
    "& h6": {
      fontWeight: "bold",
      color: "#0b0b0b",
      paddingTop: "2px",
    },
    "& p": {
      color: "#3B3B3B",
      textAlign: "right",
    },
  },
  buttonContainer: {
    textAlign: "center",
    marginBottom: "50px",
    [theme.breakpoints.up("md")]: {
      left: "35vw",
    },
  },
  button: {
    backgroundColor: "#E8C658",
    color: "#ffffff",
    borderRadius: "0px",
    padding: "15px 30px 15px 30px",
    fontWeight: "bold",
    marginTop: "40px",
    marginBottom: "10px",
    width: "78vw",
    [theme.breakpoints.up("md")]: {
      width: "30vw",
    },
  },
});

export default RecordsStyles;
