import React, { useContext, useEffect, useState } from "react";

//components
import { RoleCheck } from "../components/Shared/RoleCheck";
import Trials from "../components/Trials/Trials";
import TrialsDetails from "../components/Trials/TrialsDetails";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import profileStyles from "../assets/jss/components/ProfileStyles";
import { connect } from "react-redux";
import { AppContext } from "../App";

const TrialsView = (props) => {
  const { type, classes, searchCountryFilter } = props;
  const [filterOpen, setFilterOpen] = useState(false);
  const { deviceOs, platform, desktop } = useContext(AppContext);

  useEffect(() => {
    if (searchCountryFilter) {
      setFilterOpen(true);
    }
  }, []);

  return (
    <RoleCheck
      roles="PATIENT"
      header={"Clinical Research"}
      viewType={type === "details" ? "trialsDetail" : "filter"}
      filterOpen={filterOpen}
      setFilterOpen={setFilterOpen}
    >
      {type === "details" ? (
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.container}>
              <Grid item xs={12} className={classes.cardsContainer}>
                <Grid
                  container
                  className={classes.cardContainer}
                  style={{
                    height:
                      platform === "web" && desktop
                        ? "100%"
                        : deviceOs === "ios"
                        ? "90vh"
                        : "100vh",
                  }}
                >
                  <TrialsDetails />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent={"center"}>
          <Grid item xs={12}>
            <Grid
              container
              className={
                platform === "web" && desktop ? null : classes.container
              }
            >
              <Grid
                item
                xs={12}
                className={
                  platform === "web" && desktop ? null : classes.cardsContainer
                }
              >
                <Grid
                  container
                  className={
                    platform === "web" && desktop ? null : classes.cardContainer
                  }
                  style={{
                    height:
                      platform === "web" && desktop
                        ? "100%"
                        : deviceOs === "ios"
                        ? "90vh"
                        : "100vh",
                  }}
                >
                  <Trials
                    filterOpen={filterOpen}
                    setFilterOpen={() => setFilterOpen(!filterOpen)}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </RoleCheck>
  );
};

export function mapStateToProps(state) {
  return {
    searchCountryFilter: state.trials.searchCountryFilter,
  };
}

export default connect(
  mapStateToProps,
  null
)(withStyles(profileStyles)(TrialsView));
