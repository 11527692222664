import * as types from "./DependentsActions";

//reducer for shared actions
export const dependents = (state = {}, action) => {
  switch (action.type) {
    case types.GET_DEPENDENTS_REQUEST:
      return { ...state, getDependentsLoading: true };

    case types.GET_DEPENDENTS_SUCCESS:
      return {
        ...state,
        dependents: action.payload,
        getDependentsLoading: false,
      };

    case types.GET_DEPENDENTS_FAILURE:
      return {
        ...state,
        getDependentsLoading: false,
        getDependentsError: action.error,
      };

    case types.ADD_DEPENDENTS_REQUEST:
      return { ...state, addDependentsLoading: true };

    case types.ADD_DEPENDENTS_SUCCESS:
      return {
        ...state,
        dependents: [action.payload, ...state.dependents],
        addDependentsLoading: false,
      };

    case types.ADD_DEPENDENTS_FAILURE:
      return {
        ...state,
        addDependentsLoading: false,
        addDependentsError: action.error,
      };

    case types.UPDATE_DEPENDENTS_REQUEST:
      return { ...state, updateDependentsLoading: true };

    case types.UPDATE_DEPENDENTS_SUCCESS:
      return {
        ...state,
        dependents: state.dependents.map((item: any) =>
          item.id === action.payload?.id
            ? { ...item, ...action.payload?.data }
            : item
        ),
        updateDependentsLoading: false,
      };

    case types.UPDATE_DEPENDENTS_FAILURE:
      return {
        ...state,
        updateDependentsLoading: false,
        updateDependentsError: action.error,
      };

    case types.DELETE_DEPENDENTS_REQUEST:
      return { ...state, deleteDependentsLoading: true };

    case types.DELETE_DEPENDENTS_SUCCESS:
      return {
        ...state,
        dependents: [
          ...state.dependents.filter(
            (item: any) => item.id !== action.payload?.id
          ),
        ],
        deleteDependentsLoading: false,
      };

    case types.DELETE_DEPENDENTS_FAILURE:
      return {
        ...state,
        deleteDependentsLoading: false,
        deleteDependentsError: action.error,
      };

    case types.RESET_DEPENDENTS:
      return {};

    default:
      return state;
  }
};
