import React, { useContext, useEffect, useState } from "react";
import { bindActionCreators } from "redux";

//components
import AutocompleteInput from "../Shared/Forms/Fields/AutocompleteInput";
import { connect, useDispatch } from "react-redux";
import * as recordsActions from "../Records/RecordsActions";
import SharedStyles from "../../assets/jss/components/SharedStyles";

// mui
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Tooltip } from "@material-ui/core";
import { showConfirmation } from "../Confirmation/ConfirmationActions";
import { Dialog as CapDialog } from "@capacitor/dialog";
import { AppContext } from "../../App";

const MedicationsForm = (props) => {
  const {
    recordsActions,
    medicationList,
    medicationListLoading,
    medications,
    setRecords,
    records,
    classes,
    medicationsNA,
    setMedicationsNA,
    dependentView,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(medications || []);
  const { platform } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!medicationListLoading && medicationList) {
      setOptions(medicationList);
    }
  }, [medicationList, medicationListLoading]);

  useEffect(() => {
    setRecords({
      ...records,
      medications: selected,
    });
  }, [selected]);

  const handleClose = () => {
    setOptions([]);
    setSearchValue("");
  };

  const handleCompleteChange = (e, reason, details) => {
    e.persist();
    const selectedMedication = {
      productName: reason.brand_name,
      productNdc: reason.product_ndc,
    };
    if (selectedMedication) {
      setSelected([...selected, selectedMedication]);
    }
    setOptions([]);
    setSearchValue("");
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      return;
    } else {
      recordsActions.searchMedications(value);
    }
    setSearchValue(e.target.value);
  };

  const handleResponse = (value, item, i) => {
    if (value) {
      const selectedCopy = [...selected];
      selectedCopy.splice(i, 1);
      setSelected(selectedCopy);
    } else {
      return;
    }
  };

  const handleRemoveMedication = async (item, i) => {
    if (platform === "web") {
      dispatch(
        showConfirmation(
          "Remove Saved Medication",
          `Are you sure you want to remove this medication?`,
          "removeRecordItem",
          (e) => handleResponse(e, item, i)
        )
      );
    } else {
      const { value } = await CapDialog.confirm({
        title: "Remove Saved Medication",
        message: `Are you sure you want to remove this medication?`,
        cancelButtonTitle: "Cancel",
        okButtonTitle: "Ok",
      });
      if (value) {
        handleResponse(true, item, i);
      }
    }
  };

  return (
    <Grid container justifyContent={"center"} spacing={3}>
      <Grid item xs={12} style={{ backgroundColor: "#EBEBEB" }}>
        <Typography>
          What medication(s) {dependentView ? "is your dependent " : "are you "}{" "}
          currently taking?
        </Typography>
      </Grid>
      <Grid item xs={11} md={10}>
        <Typography className={classes.title} style={{ marginTop: "20px" }}>
          My {dependentView ? "Dependents" : ""} Medications
        </Typography>
        {((records &&
          records.medications &&
          records.medications.length === 0) ||
          !records.medications) && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={medicationsNA}
                    onChange={() => setMedicationsNA(!medicationsNA)}
                    name="medicationsNA"
                    color="primary"
                  />
                }
                label={`${
                  dependentView ? "My dependent does not " : "I do not "
                } take any medications`}
              />
            </FormControl>
          </Grid>
        )}
        <List dense>
          {selected.map((item, i) => {
            return (
              <div key={`medications-list-${i}`}>
                <ListItem>
                  <ListItemText
                    // primary={item}
                    primary={`${item.productName}`}
                    secondary={`Product NDC: ${item.productNdc}`}
                  />
                  <ListItemSecondaryAction>
                    <Tooltip title={"Delete Medication"}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveMedication(item, i)}
                      >
                        <DeleteIcon color={"secondary"} />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </div>
            );
          })}
        </List>
      </Grid>
      {!medicationsNA && (
        <Grid item xs={11} md={10}>
          <AutocompleteInput
            searchValue={searchValue}
            // handleClick={handleClick}
            handleChange={handleChange}
            handleCompleteChange={handleCompleteChange}
            handleClose={handleClose}
            options={options}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.brand_name
            }
            placeholder={"Search to add medication"}
            loading={medicationListLoading}
            helperText={
              "Search the medication per the name on your medication label"
            }
            customRender={(option) => {
              const ingredients = [];
              option &&
                option.active_ingredients &&
                option.active_ingredients.map((item) => {
                  ingredients.push(`${item.name} (${item.strength})`);
                });
              return (
                <ListItem>
                  <ListItemText
                    primary={`${option.brand_name}`}
                    secondary={`Active Ingredients: ${ingredients.join(", ")}`}
                  />
                </ListItem>
              );
            }}
          />
        </Grid>
      )}
    </Grid>
  );
};

export function mapStateToProps(state) {
  return {
    medicationList: state.records.medicationList,
    medicationListLoading: state.records.medicationListLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recordsActions: bindActionCreators(recordsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(SharedStyles)(MedicationsForm));
