const ListStyles = (theme) => ({
  container: {
    height: "inherit",
    paddingTop: 20,
    position: "relative",
  },
  card: {
    padding: "10px 15px 25px 15px",
    "& h1": {
      fontSize: "6vw",
      fontWeight: "400",
    },
    "& p": {
      fontSize: "3.75vw",
      paddingTop: "12px",
    },
  },
  searchContainer: {
    position: "fixed",
    zIndex: "1",
    width: 400,
    left: 0,
    backgroundColor: "#ffffff",
    [theme.breakpoints.up("md")]: {
      width: "75%",
      left: "inherit",
    },
  },
  cardContainer: {
    top: 115,
    right: 24,
    // width: '90%',
    paddingTop: 55,
    overflow: "auto",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordion-root:before": {
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .MuiAccordion-root": {
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  cardsContainer: {
    overflow: "auto",
  },
  iconButton: {
    marginTop: "-6.5vh",
    float: "right",
  },
  cardGridContainer: {
    padding: "0 5px 15px",
    "& h1": {
      fontSize: "18px",
      fontWeight: "bold",
      textAlign: "left",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    "& p": {
      fontSize: "3.75vw",
      paddingTop: "10px",
      textAlign: "left",
    },
  },
  cardGrid: {
    padding: "10px",
    borderRadius: "0px",
    height: "30vh",
    maxHeight: "300px",
    textAlign: "center",
  },
  photoGrid: {
    maxWidth: "100%",
    maxHeight: "160px",
    paddingBottom: "10px",
  },
  bottomButtonContainer: {
    textAlign: "center",
    paddingBottom: "4.5vh",
    left: "12vw",
    [theme.breakpoints.up("md")]: {
      left: "35vw",
    },
  },
  bottomButton: {
    backgroundColor: "#3F1F99",
    color: "#ffffff",
    borderRadius: "0px",
    padding: "15px 30px 15px 30px",
    fontWeight: "bold",
    marginTop: "10vw",
    width: "78vw",
    [theme.breakpoints.up("md")]: {
      width: "30vw",
    },
  },
});

export default ListStyles;
