import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";

//redux
import history from "../Shared/Redux/history";
import { bindActionCreators } from "redux";
import * as trialsActions from "../Trials/TrialsActions";
import * as recordsActions from "../Records/RecordsActions";
import { connect, useDispatch, useSelector } from "react-redux";

//components
import List from "../Shared/List/List";
import SharedStyles from "../../assets/jss/components/SharedStyles";
import { AppContext } from "../../App";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import {
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import * as userActions from "../User/UserActions";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import { showConfirmation } from "../Confirmation/ConfirmationActions";
import { Dialog as CapDialog } from "@capacitor/dialog";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Trials = (props) => {
  const {
    classes,
    trialsActions,
    searchTrials,
    searchTrialsLoading,
    filterOpen,
    myRecords,
    recordsActions,
    searchCountryFilter,
    setFilterOpen,
    savedTrials,
    savedTrialsLoading,
  } = props;
  const { online, userDetails, platform, desktop } = useContext(AppContext);
  const [value, setValue] = useState("");
  const [limit] = useState(10);
  const [skip, setSkip] = useState(0);
  const [country, setCountry] = useState("");
  const [conditions, setConditions] = useState([]);
  const [statuses, setStatuses] = useState(["recruiting"]);
  const [tabValue, setTabValue] = useState(0);
  const userSavedTrials = useSelector((state) => state.user.userSavedTrials);
  const userSavedTrialsLoading = useSelector(
    (state) => state.user.updateTrialLoading
  );
  const savingTrialLoadingId = useSelector((state) => state.user.loadingID);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (searchCountryFilter) {
      setCountry(searchCountryFilter);
    }
    return () => {
      if (!window.location.pathname.includes("trials")) {
        trialsActions.resetTrialsSearch();
      }
    };
  }, []);

  useEffect(() => {
    if (location.search.includes("saved")) {
      setTabValue(1);
    } else {
      setTabValue(0);
      history.push(`/clinical-research?id=search`);
    }
  }, []);

  useEffect(() => {
    return () => {
      recordsActions.resetRecords();
    };
  }, [online]);

  useEffect(() => {
    if (tabValue === 1 && userSavedTrials && userSavedTrials.length) {
      trialsActions.searchTrials(
        0,
        100,
        "end_date",
        "ASC",
        "",
        "",
        userSavedTrials.join(",")
      );
    }
  }, [tabValue, userDetails]);

  useEffect(() => {
    if (online) {
      recordsActions.getUserRecords(
        userDetails.dependant ? userDetails.id : null
      );
    }
  }, [online, userDetails]);

  useEffect(() => {
    if (myRecords) {
      if (myRecords.conditions) {
        // remove duplicates from conditions array and sort alphabetically
        let filteredConditions = [...new Set(myRecords.conditions)].sort();
        setConditions(filteredConditions);
      }
    }
  }, [myRecords]);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    history.push(
      `/clinical-research?id=${newValue === 0 ? "search" : "saved"}`
    );
  };

  const handleClick = (e, option) => {
    let node = e.target.nodeName;
    if (node !== "BUTTON" && node !== "svg" && node !== "path") {
      history.push({
        pathname: `/clinical-research/${option.id}`,
        state: { trial: option },
      });
    }
  };

  const handleChange = (e) => {
    trialsActions.resetTrialsSearch();
    let query = e.target.value;
    setValue(query);
    if (query.length > 2) {
      trialsActions.searchTrials(
        skip,
        limit,
        "end_date",
        "ASC",
        query,
        country,
        null,
        statuses.join("|")
      );
    }
  };

  const handleScrollUpdate = () => {
    trialsActions.searchTrials(
      skip + 10,
      limit,
      "end_date",
      "ASC",
      value,
      country,
      null,
      statuses.join("|")
    );
    setSkip(skip + 10);
  };

  const handleCountryFilter = (v) => {
    if (v !== country) {
      trialsActions.resetTrialsSearch();
      setCountry(v);
      if (value.length) {
        trialsActions.searchTrials(
          0,
          10,
          "start_date",
          "DESC",
          value,
          v,
          null,
          statuses.join("|")
        );
      }
    }
  };

  const handleStatusFilter = (v) => {
    if (v !== statuses) {
      trialsActions.resetTrialsSearch();
      setStatuses(v);
    }
  };

  const handleApplyStatusFilter = () => {
    if (value.length) {
      trialsActions.searchTrials(
        0,
        10,
        "_id",
        "ASC",
        value,
        country,
        null,
        statuses.join("|")
      );
    }
  };

  const handleSaveTrial = (id) => {
    dispatch(
      userActions?.saveClinicalTrial(
        id,
        userDetails.dependant ? userDetails.id : null
      )
    );
  };

  const handleRemoveTrial = async (id) => {
    if (platform === "web") {
      dispatch(
        showConfirmation(
          "Remove Saved Clinical Research",
          `Are you sure you want to remove this clinical research?`,
          "removeClinicalTrial",
          id
        )
      );
    } else {
      const { value } = await CapDialog.confirm({
        title: "Remove Saved Clinical Research",
        message: `Are you sure you want to remove this clinical research?`,
        cancelButtonTitle: "Cancel",
        okButtonTitle: "Ok",
      });
      if (value) {
        dispatch(
          userActions?.removeClinicalTrial(
            id,
            userDetails.dependant ? userDetails.id : null
          )
        );
      }
    }
  };

  const listRender = (option) => {
    return (
      <Grid
        container
        spacing={2}
        justifyContent={
          platform === "web" && desktop ? "flex-start" : "space-between"
        }
        className={classes.listItems}
      >
        <Grid item xs={1}>
          {userSavedTrialsLoading && savingTrialLoadingId === option.id ? (
            <IconButton>
              <CircularProgress color={"secondary"} size={20} />
            </IconButton>
          ) : userSavedTrials && userSavedTrials.includes(option.id) ? (
            <IconButton onClick={() => handleRemoveTrial(option.id)}>
              <StarIcon color={"secondary"} />
            </IconButton>
          ) : (
            <IconButton onClick={() => handleSaveTrial(option.id)}>
              <StarBorderIcon color={"secondary"} />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">
                {option.brief_title || option.title || option.id}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "2px" }}>
              <Typography variant="subtitle2" color={"primary"}>
                <b>Conditions</b>: {option.conditions.join(", ")}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "2px" }}>
              <Typography variant="subtitle2" color={"primary"}>
                <b>Status</b>: {option.status}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ paddingTop: "2px" }}>
              <Typography variant="subtitle2" color={"primary"}>
                <b>Trial Duration</b>: {option.start_date} to{" "}
                {option.completion_date}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container className={classes.sharedMain}>
      <Grid item xs={12} className={classes.sharedPageContainer}>
        <div
          style={{
            position: "fixed",
            top: platform === "web" && desktop ? 15 : -50,
            zIndex: 2,
            width: platform === "web" && desktop ? "75%" : 400,
            marginLeft: platform === "web" && desktop ? 25 : -10,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{
              marginTop: platform === "web" && desktop ? 50 : 110,
              backgroundColor: "#fff",
            }}
          >
            <Tab label="Search" />
            <Tab label="Saved" />
          </Tabs>
        </div>
        <TabPanel value={tabValue} index={0}>
          <List
            handleClick={handleClick}
            handleChange={handleChange}
            viewType={"detailedTrialsList"}
            options={searchTrials || []}
            placeholder={"Search title, description or condition"}
            noResultsMessage={
              value === ""
                ? "Use search bar to find Clinical Research opportunities. The results from this search will yield actively recruiting trials for the disease indicated. The search results do not exactly match to your biomedical data entered."
                : "No Clinical Research Found"
            }
            dropdown={"searchbar"}
            searchValue={value}
            setSearchValue={setValue}
            setFilterValue={handleCountryFilter}
            filterValue={country}
            statusFilterValue={statuses}
            setStatusFilterValue={handleStatusFilter}
            styling={{
              backgroundColor: "#8F3749",
              color: "#ffffff",
            }}
            clearSearch={() => trialsActions.resetTrialsSearch()}
            loading={searchTrialsLoading}
            loadingMessage={`Searching over 400,000 trials to find the best matches for ${value} with status ${
              statuses.join(", ") || "ALL"
            }`}
            handleScrollUpdate={handleScrollUpdate}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
            listRender={listRender}
            quickSearchChips={conditions}
            handleApplyStatusFilter={handleApplyStatusFilter}
          />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <List
            handleClick={handleClick}
            viewType={"detailedTrialsList"}
            options={savedTrials || []}
            noResultsMessage={
              value === "" ? "No Trials Saved" : "No Trials Found"
            }
            dropdown={""}
            styling={{
              backgroundColor: "#8F3749",
              color: "#ffffff",
            }}
            loading={savedTrialsLoading}
            listRender={listRender}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
};

export function mapStateToProps(state) {
  return {
    searchTrials: state.trials.searchTrials,
    savedTrials: state.trials.savedTrials,
    searchCountryFilter: state.trials.searchCountryFilter,
    searchTrialsLoading: state.trials.searchTrialsLoading,
    savedTrialsLoading: state.trials.savedTrialsLoading,
    myRecords: state.records.myRecords,
    myRecordsError: state.records.myRecordsError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    trialsActions: bindActionCreators(trialsActions, dispatch),
    recordsActions: bindActionCreators(recordsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(SharedStyles)(Trials));
