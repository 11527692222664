import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import { sec } from "../../security";
import config from "../../auth.config";
import { getUserBalance } from "../Payments/PaymentsActions";
const API_ROOT = process.env.REACT_APP_API_ROOT;

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";
export const RESET_USER_DETAILS = "RESET_USER_DETAILS";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const GET_USER_PROFILE_PIC_REQUEST = "GET_USER_PROFILE_PIC_REQUEST";
export const GET_USER_PROFILE_PIC_SUCCESS = "GET_USER_PROFILE_PIC_SUCCESS";
export const GET_USER_PROFILE_PIC_FAILURE = "GET_USER_PROFILE_PIC_FAILURE";

export const UPLOAD_USER_PIC_REQUEST = "UPLOAD_USER_PIC_REQUEST";
export const UPLOAD_USER_PIC_SUCCESS = "UPLOAD_USER_PIC_SUCCESS";
export const UPLOAD_USER_PIC_FAILURE = "UPLOAD_USER_PIC_FAILURE";

export const GET_USER_REWARDS_REQUEST = "GET_USER_REWARDS_REQUEST";
export const GET_USER_REWARDS_SUCCESS = "GET_USER_REWARDS_SUCCESS";
export const GET_USER_REWARDS_FAILURE = "GET_USER_REWARDS_FAILURE";

export const NEW_USER_CONSENT_REQUEST = "NEW_USER_CONSENT_REQUEST";
export const NEW_USER_CONSENT_SUCCESS = "NEW_USER_CONSENT_SUCCESS";
export const NEW_USER_CONSENT_FAILURE = "NEW_USER_CONSENT_FAILURE";

export const GET_TERMS_REQUEST = "GET_TERMS_REQUEST";
export const GET_TERMS_SUCCESS = "GET_TERMS_SUCCESS";
export const GET_TERMS_FAILURE = "GET_TERMS_FAILURE";

export const UPDATE_CLINICAL_TRIAL_REQUEST = "UPDATE_CLINICAL_TRIAL_REQUEST";
export const UPDATE_CLINICAL_TRIAL_SUCCESS = "UPDATE_CLINICAL_TRIAL_SUCCESS";
export const REMOVE_SAVED_TRIAL_SUCCESS = "REMOVE_SAVED_TRIAL_SUCCESS";

export const getUser = (userId, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: GET_USER_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/app/user`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      // alert(`ERROR ${error}`);
      dispatch({
        type: GET_USER_FAILURE,
        error: error.response.data.message,
      });
      // dispatch(
      //   showNotification(
      //     error.response.data.message ||
      //       "Oops! Something went wrong. Please try again.",
      //     "error"
      //   )
      // );
    });
};

export const updateUser = (user, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: UPDATE_USER_REQUEST });

  return axios({
    method: "PUT",
    url: `${API_ROOT}/app/user`,
    data: user,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: response.data,
      });
      dispatch(showNotification("User Info Updated", "success"));
    })
    .catch((error) => {
      dispatch({
        type: UPDATE_USER_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getUserProfilePic = (userId, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: GET_USER_PROFILE_PIC_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/app/user/pic`,
    responseType: "blob",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
  })
    .then((response) => {
      let url = URL.createObjectURL(response.data);
      let img = new Image();
      img.src = url;
      img.alt = "user profile avatar";

      dispatch({
        type: GET_USER_PROFILE_PIC_SUCCESS,
        payload: img,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_USER_PROFILE_PIC_FAILURE,
        error: error.response.data.message,
      });
      // dispatch(
      //   showNotification(
      //     error.response.data.message ||
      //       "Oops! Something went wrong. Please try again.",
      //     "error"
      //   )
      // );
    });
};

export const userPing = () => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });

  axios({
    method: "GET",
    url: `${API_ROOT}/app/user/ping`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
  })
    .then(() => {
      console.log("Resources requested.");
    })
    .catch((error) => {
      console.log(
        "There was an issue requesting resources sync.",
        error.response.data.message
      );
    });
};

export const uploadUserPic = (file, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: UPLOAD_USER_PIC_REQUEST });

  const formData = new FormData();
  formData.append("image", file);

  let url = URL.createObjectURL(file);
  let img = new Image();
  img.src = url;
  img.alt = "user profile avatar";

  return axios({
    method: "POST",
    url: `${API_ROOT}/app/user/pic`,
    data: formData,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
  })
    .then((response) => {
      dispatch({
        type: UPLOAD_USER_PIC_SUCCESS,
        payload: img,
      });
      dispatch(showNotification("Profile Picture Updated", "success"));
    })
    .catch((error) => {
      dispatch({
        type: UPLOAD_USER_PIC_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getUserRewards = () => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: GET_USER_REWARDS_REQUEST });

  axios({
    method: "GET",
    url: `${API_ROOT}/app/reward/initial`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_REWARDS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(
        "There was an issue fetching user rewards.",
        error.response.data.message
      );
    });
};

export const claimUserRewards = (id) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: GET_USER_REWARDS_REQUEST });

  axios({
    method: "PUT",
    url: `${API_ROOT}/app/reward/initial/claim`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
    params: {
      accountId: id,
    },
  })
    .then((response) => {
      dispatch(
        showNotification(
          `You have successfully claimed your HBAR rewards to account ${id}`,
          "success"
        )
      );
      dispatch({
        type: GET_USER_REWARDS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(
        "There was an issue claiming user rewards.",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const newUserConsent = (wordingKey, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: NEW_USER_CONSENT_REQUEST });

  axios({
    method: "POST",
    url: `${API_ROOT}/app/user/consent`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
    params: {
      wordingKey,
    },
  })
    .then((response) => {
      dispatch({
        type: NEW_USER_CONSENT_SUCCESS,
        payload: response.data,
      });
      if (actAsId) {
        dispatch(showNotification("Dependent successfully added", "success"));
      }
    })
    .catch((error) => {
      console.log(
        "There was an issue claiming user rewards.",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const getTermsWording = () => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: GET_TERMS_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/app/wording`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_TERMS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_TERMS_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const saveClinicalTrial = (id, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: UPDATE_CLINICAL_TRIAL_REQUEST, payload: id });

  axios({
    method: "POST",
    url: `${API_ROOT}/app/user/clinical-trial/add/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_CLINICAL_TRIAL_SUCCESS,
        payload: response.data.config.savedClinicalTrials,
      });
      dispatch(
        showNotification("Clinical research successfully saved", "success")
      );
      // dispatch(getUserBalance(actAsId || ""));
    })
    .catch((error) => {
      console.log(
        "There was an issue saving the clinical research.",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const removeClinicalTrial = (id, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: UPDATE_CLINICAL_TRIAL_REQUEST });

  axios({
    method: "DELETE",
    url: `${API_ROOT}/app/user/clinical-trial/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
  })
    .then((response) => {
      console.log(response.data);
      dispatch({
        type: UPDATE_CLINICAL_TRIAL_SUCCESS,
        payload: response.data.config.savedClinicalTrials,
      });
      dispatch({
        type: REMOVE_SAVED_TRIAL_SUCCESS,
        payload: id,
      });
      dispatch(
        showNotification(
          "Clinical research successfully removed from saved list",
          "success"
        )
      );
    })
    .catch((error) => {
      console.log(
        "There was an issue removing the clinical research.",
        error.response.data.message
      );
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};

export const resetUserDetails = () => async (dispatch) => {
  dispatch({
    type: RESET_USER_DETAILS,
  });
};
