import React from "react";

//material-ui
import {
  Grid,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";

const AccordionList = (props) => {
  const { index, option, optionNames, expanded, setExpanded } = props;

  const handleAccordionChange = (panel) => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      key={index}
      style={{ width: "100%", padding: "5px" }}
      expanded={expanded === index}
      onChange={handleAccordionChange(index)}
    >
      <AccordionSummary
        aria-controls="panel1a-content"
        id={`panel${option.sequenceNo}a-header`}
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography style={{ fontSize: "18px" }}>
              {optionNames ? option[optionNames[0]] : option.name}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ marginTop: "-10px" }}>
        <Typography component={"span"} style={{ fontSize: "16px" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: optionNames ? option[optionNames[1]] : option.description,
            }}
          />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionList;
