import React from "react";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import ListStyles from "../../../assets/jss/components/ListStyles";
import { Grid, IconButton } from "@material-ui/core";
import StarBorderOutlinedIcon from "@material-ui/icons/StarBorderOutlined";
import StarOutlinedIcon from "@material-ui/icons/StarOutlined";

const TextList = (props) => {
  const {
    classes,
    index,
    handleClick,
    option,
    styling,
    star,
    handleStarClick,
    listRender,
  } = props;

  return (
    <Grid
      key={index}
      item
      xs={12}
      className={classes.card}
      style={styling || null}
      onClick={(e) => handleClick(e, option)}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item xs={star ? 10 : 12}>
          {listRender(option)}
        </Grid>
        {star && (
          <Grid item xs={2}>
            <IconButton onClick={() => handleStarClick(option)}>
              {option.starred ? (
                <StarOutlinedIcon style={{ color: "#E84545" }} />
              ) : (
                <StarBorderOutlinedIcon style={{ color: "#BDBDBD" }} />
              )}
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default withStyles(ListStyles)(TextList);
