import React from "react";
import InputMask from "react-input-mask";
import { relationships } from "./helpers/OnboardingHelpers";

//material-ui
import { Grid, Typography, TextField, MenuItem } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../assets/jss/components/OnboardingStyles";

const EmergencyForm = (props) => {
  const { classes, step, setActiveStep, handleChange } = props;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={classes.formBanner}
        style={{ backgroundColor: "#8F3749" }}
      >
        <Typography variant="h1">Emergency</Typography>
        <Typography variant="h1">Contact</Typography>
      </Grid>
      <Grid item xs={12} className={classes.emergencyContainer}>
        <Grid container>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="First Name"
              fullWidth
              onChange={(e) => handleChange(e, "emergencyContact.firstName")}
              inputProps={{ "data-testid": "emergencyContact.firstName" }}
            />
          </Grid>
          <Grid item xs={12} style={{ padding: "20px 0 0 0" }}>
            <TextField
              variant="outlined"
              label="Last Name"
              fullWidth
              onChange={(e) => handleChange(e, "emergencyContact.lastName")}
              inputProps={{ "data-testid": "emergencyContact.lastName" }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-select-currency"
              select
              label="Relationship"
              variant="outlined"
              fullWidth
              className={classes.formInputLarge}
              onChange={(e) => handleChange(e, "emergencyContact.relationship")}
              inputProps={{ "data-testid": "emergencyContact.relationship" }}
            >
              {relationships.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <InputMask
              mask="(999) 999-9999"
              onChange={(e) =>
                handleChange(e, "emergencyContact.contact.mobileNumber")
              }
            >
              {(inputProps) => (
                <TextField
                  {...inputProps}
                  variant="outlined"
                  label="Phone Number"
                  fullWidth
                  type="tel"
                  className={classes.formInputLarge}
                  inputProps={{
                    "data-testid": "emergencyContact.contact.mobileNumber",
                  }}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={12} className={classes.formSkipText}>
            <Typography
              variant="subtitle1"
              onClick={() => setActiveStep(step.page + 4)}
            >
              Skip for right now
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(OnboardingStyles)(EmergencyForm);
