import React from "react";

//components
import InputMask from "react-input-mask";

//styling
import { TextField } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../../../assets/jss/components/OnboardingStyles";

const TextFieldInput = (props) => {
  const {
    classes,
    variant,
    label,
    fullWidth,
    className,
    style,
    type,
    handleChange,
    passedValue,
    mask,
    value,
    error,
    required,
  } = props;
  let input;

  if (mask) {
    input = (
      <InputMask
        mask={mask}
        onChange={(e) => handleChange(e, passedValue)}
        value={value}
      >
        {(inputProps) => (
          <TextField
            {...inputProps}
            variant={"outlined" || variant}
            label={label}
            fullWidth={true || fullWidth}
            type={type}
            className={className ? classes.formInputLarge : ""}
            style={style}
            // error={error[passedValue]}
            // helperText={error[passedValue]}
            inputProps={{
              "data-testid": `textField.${label
                .replace(" ", ".")
                .toLowerCase()}`,
            }}
            required={required}
          />
        )}
      </InputMask>
    );
  } else {
    input = (
      <TextField
        variant={"outlined" || variant}
        label={label}
        fullWidth={true || fullWidth}
        className={className ? classes.formInputLarge : ""}
        style={style}
        onChange={(e) => handleChange(e, passedValue)}
        value={value}
        // error={error[passedValue]}
        // helperText={error[passedValue]}
        // inputProps={{
        //   "data-testid": `textField.${label.replace(" ", ".").toLowerCase()}`,
        // }}
        required={required}
      />
    );
  }

  return <div>{input}</div>;
};

export default withStyles(OnboardingStyles)(TextFieldInput);
