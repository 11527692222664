export const neuroblastomaFields = (dependentView) => [
  {
    type: "dropdown",
    name: "neuroBlastomaStage",
    question: `What stage of neuroblastoma ${
      dependentView ? "does your dependent have" : "do you have"
    }?`,
    options: ["1", "2a", "2b", "3", "4", "4s", "I don't know"],
    multiSelect: false,
    show: true,
  },
  {
    type: "boolean",
    name: "deceased",
    question: "Is your dependent deceased?",
    show: dependentView,
  },
  {
    type: "boolean",
    name: "hasRelapsed",
    question: `${dependentView ? "Has your dependent" : "Have you"} relapsed?`,
    show: true,
  },
  {
    type: "dropdown",
    name: "tumorAreas",
    question: `Please indicate the tumor area(s)`,
    options: [
      "Kidney",
      "Chest",
      "Pelvis",
      "Abdomen",
      "Bone Marrow",
      "Lymph Nodes",
      "Liver",
      "Skin",
      "None of the above",
    ],
    multiSelect: true,
    show: true,
  },
  {
    type: "dropdown",
    name: "recentProcedures",
    question: `Please list any of the following treatments ${
      dependentView ? "your dependent has" : "you have"
    } undergone within the last 12 months? You may select more than one.`,
    options: [
      "Chemotherapy",
      "Radiation",
      "Chemo/Radiation Combination Therapy",
      "Surgery (i.e. tumor removal)",
      "Immunotherapy",
      "Stem Cell Transplant",
      "MIBG Therapy",
      "None of the above",
    ],
    multiSelect: true,
    show: true,
  },
  {
    type: "dropdown",
    name: "currentProcedures",
    question: `Please indicate which of the following treatments or therapy ${
      dependentView ? "your dependent is" : "you are"
    } currently (as of today) receiving?`,
    options: [
      "Chemotherapy",
      "Radiation",
      "Chemo/Radiation Combination Therapy",
      "Surgery (i.e. tumor removal)",
      "Immunotherapy",
      "Stem Cell Transplant",
      "MIBG Therapy",
      "None of the above",
    ],
    freeText: true,
    multiSelect: false,
    show: true,
  },
  {
    type: "boolean",
    name: "geneticTesting",
    question: "Was a genetic test carried out?",
    show: true,
  },
  {
    type: "dropdown",
    name: "geneMutations",
    question: "If the above answer is yes, were there any gene mutations?",
    options: [
      "MYCN",
      "ALK",
      "ATRX",
      "ABL1",
      "AFF1",
      "ARAF",
      "ATM",
      "BARD1",
      "BCR",
      "BRAF",
      "BRCA1",
      "BRCA2",
      "BRIP1",
      "CDK12",
      "CHEK1",
      "CHEK2",
      "CRKL",
      "DEK",
      "EGFR",
      "ELL",
      "ERBB2",
      "ERBB4",
      "FANCA",
      "FANCB",
      "FANCC",
      "FANCD2",
      "FANCE",
      "FANCF",
      "FANCG",
      "FANCI",
      "FANCL",
      "FANCM",
      "FLT3",
      "HRAS",
      "KMT2A",
      "KRAS",
      "MAP2K1",
      "MAP2K2",
      "MAP2K4",
      "MAP3K1",
      "MAPK1",
      "MCPH1",
      "MECOM",
      "MLLT1",
      "MLLT10",
      "MLLT3",
      "MLLT4",
      "MRE11A",
      "MYC",
      "NBN",
      "NF1",
      "NRAS",
      "NRIP3",
      "NUP214",
      "PALB2",
      "PTEN",
      "RAD50",
      "RAD51",
      "RAD51B",
      "RAD51C",
      "RAD51D",
      "RAD54L",
      "RAF1",
      "RPN1",
      "SLX4",
      "SRC",
      "TP53",
    ],
    multiSelect: true,
    show: true,
  },
];
