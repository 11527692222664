import React, { useContext, useState } from "react";

//components
import * as paymentsActions from "./PaymentsActions";

// mui
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  TextField,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { AppContext } from "../../App";
import MetricsCount from "./MetricCount";
import CheckIcon from "@material-ui/icons/Check";

const PaymentsMetrics = (props) => {
  const { platform, desktop } = useContext(AppContext);
  const { classes } = props;
  const dispatch = useDispatch();
  const userBalance = useSelector((state) => state.balance.userBalance);
  const redeemLoading = useSelector((state) => state.balance.redeemLoading);
  const redeemStatus = useSelector((state) => state.balance.redeemStatus);
  const userDetails = useSelector((state) => state.user.userDetails);
  const [openRedeemDialog, setOpenRedeemDialog] = useState(false);
  const [paypalEmail, setPaypalEmail] = useState("");

  const handleRedeem = () => {
    const data = {
      redeemEmail: paypalEmail,
      amount: userBalance.balance,
    };
    dispatch(
      paymentsActions.redeemRewards(
        data,
        userDetails.dependant ? userDetails.id : null
      )
    );
  };

  return (
    <Grid
      container
      justifyContent={"space-around"}
      alignItems={"center"}
      style={{ backgroundColor: "#fff", padding: 5 }}
    >
      <Grid item>
        <MetricsCount
          title={"Total Rewards Earned"}
          value={userBalance.payments}
        />
      </Grid>
      <Grid item>
        <MetricsCount
          title={"Total Rewards Redeemed"}
          value={userBalance.redeems}
        />
      </Grid>
      <Grid item>
        <MetricsCount title={"Balance Remaining"} value={userBalance.balance} />
      </Grid>
      <Grid item>
        <Button
          variant={"contained"}
          color={"secondary"}
          onClick={() => setOpenRedeemDialog(true)}
          size={"small"}
          disabled={userBalance.balance === 0}
        >
          Cash out
        </Button>
      </Grid>
      <Dialog
        onClose={() => setOpenRedeemDialog(false)}
        aria-labelledby="simple-dialog-title"
        open={openRedeemDialog}
        maxWidth={platform === "web" && desktop ? "sm" : "lg"}
        fullWidth
        fullScreen={!(platform === "web" && desktop)}
      >
        <DialogTitle disableTypography className={classes.root}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs={1}>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={() => setOpenRedeemDialog(false)}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant={"h5"} style={{ fontWeight: "bold" }}>
                {"Redeem Rewards"}
              </Typography>
            </Grid>
            <Grid item xs={1}></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid
            container
            alignItems={"center"}
            spacing={2}
            direction={"column"}
          >
            <Grid item>
              <img
                src="https://www.paypalobjects.com/webstatic/mktg/Logo/pp-logo-200px.png"
                alt=""
                height={30}
              />
            </Grid>
            {!redeemLoading && !redeemStatus && (
              <Grid item xs={11}>
                <Grid container justifyContent={"center"} spacing={2}>
                  <Grid item>
                    <Typography style={{ textAlign: "center" }}>
                      Redeemed rewards will be sent to your PayPal account.
                      Please enter your email associated with your PayPal
                      account to redeem.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      style={{ textAlign: "center" }}
                      color={"secondary"}
                    >
                      You have $
                      {Intl.NumberFormat("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }).format(userBalance.balance)}{" "}
                      available to redeem.
                    </Typography>
                  </Grid>
                  <Grid item xs={11} md={8}>
                    <TextField
                      variant="outlined"
                      label="PayPal Email"
                      fullWidth
                      onChange={(e) => setPaypalEmail(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            {redeemLoading && (
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
            )}
            {redeemStatus &&
              redeemStatus.transferTransactionStatus === "SUCCESS" && (
                <Grid item>
                  <Grid
                    container
                    alignItems={"center"}
                    direction={"column"}
                    spacing={2}
                  >
                    <Grid item>
                      <Fab
                        aria-label="save"
                        color="primary"
                        style={{ backgroundColor: "green", cursor: "default" }}
                      >
                        <CheckIcon />
                      </Fab>
                    </Grid>
                    <Grid item>
                      <Typography variant={"h6"} color={"primary"}>
                        You have successfully redeemed your rewards! Please
                        check your PayPal account with provided email to confirm
                        a successful transfer.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </DialogContent>
        {redeemStatus && redeemStatus.transferTransactionStatus === "SUCCESS" && (
          <DialogActions>
            <Button
              onClick={() => setOpenRedeemDialog(false)}
              color="primary"
              variant={"contained"}
            >
              Close
            </Button>
          </DialogActions>
        )}
        {!redeemLoading && !redeemStatus && (
          <DialogActions>
            <Button onClick={() => setOpenRedeemDialog(false)} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleRedeem}
              color="secondary"
              variant={"contained"}
              disabled={!paypalEmail}
            >
              Redeem
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </Grid>
  );
};

export default PaymentsMetrics;
