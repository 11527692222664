import axios from "axios";
import { showNotification } from "../Notifications/NotificationsActions";
import { sec } from "../../security";
import config from "../../auth.config";
const API_ROOT = process.env.REACT_APP_API_ROOT;

//Action Types
export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

//Thunks
export const getFaqs = () => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: GET_FAQ_REQUEST });

  return axios({
    method: "GET",
    url: `${API_ROOT}/app/faq`,
    withCredentials: true,
    crossorigin: true,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
  })
    .then((response) => {
      dispatch({
        type: GET_FAQ_SUCCESS,
        payload: response.data.items,
      });
    })
    .catch((error) => {
      dispatch({
        type: GET_FAQ_FAILURE,
        error: error.response.data.message,
      });
      dispatch(
        showNotification(
          error.response.data.message ||
            "Oops! Something went wrong. Please try again.",
          "error"
        )
      );
    });
};
