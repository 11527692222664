import axios from "axios";
import { sec } from "../../security";
import config from "../../auth.config";

//======== Action Types =========

// ***************** CLINICAL TRIALS TYPES ***************** //
export const SEARCH_TRIALS_REQUEST = "SEARCH_TRIALS_REQUEST";
export const SEARCH_TRIALS_SUCCESS = "SEARCH_TRIALS_SUCCESS";
export const SEARCH_TRIALS_FAILURE = "SEARCH_TRIALS_FAILURE";
export const SAVED_TRIALS_REQUEST = "SAVED_TRIALS_REQUEST";
export const FETCH_SAVED_SUCCESS = "FETCH_SAVED_SUCCESS";
export const RECOMMENDED_TRIALS_REQUEST = "RECOMMENDED_TRIALS_REQUEST";
export const RECOMMENDED_TRIALS_SUCCESS = "RECOMMENDED_TRIALS_SUCCESS";
export const RESET_TRIALS_RECOMMENDED = "RESET_TRIALS_RECOMMENDED";

export const RESET_TRIALS_SEARCH = "RESET_TRIALS_SEARCH";
const API_ROOT = process.env.REACT_APP_API_ROOT;

export const searchTrials = (
  skip,
  limit,
  sort,
  direction,
  q,
  country,
  ids,
  statuses
) => {
  const thunk = async (dispatch, getState) => {
    if (ids && ids.length > 0) {
      dispatch({ type: SAVED_TRIALS_REQUEST });
    } else if (limit === 5) {
      dispatch({ type: RECOMMENDED_TRIALS_REQUEST });
    } else {
      dispatch({ type: SEARCH_TRIALS_REQUEST });
    }
    const access_token = await sec.getAccessTokenSilently()({
      audience: config.audience,
    });
    const id_token = await sec.getIdTokenClaims()({
      audience: config.audience,
    });

    return axios({
      method: "GET",
      url: `${API_ROOT}/app/clinical-trial/list`,
      params: {
        skip,
        limit,
        sort,
        direction,
        q,
        country,
        ids,
        statuses,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        Id_Token: id_token.__raw,
      },
    })
      .then((response) => {
        if (ids && ids.length > 0) {
          dispatch({
            type: FETCH_SAVED_SUCCESS,
            payload: {
              data: response.data.items,
            },
          });
        } else if (limit === 5) {
          dispatch({
            type: RECOMMENDED_TRIALS_SUCCESS,
            payload: {
              data: response.data.items,
              country: country,
            },
          });
        } else {
          dispatch({
            type: SEARCH_TRIALS_SUCCESS,
            payload: {
              data: response.data.items,
              country: country,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SEARCH_TRIALS_FAILURE,
        });
      });
  };
  thunk.meta = {
    debounce: {
      time: 800,
      key: "filter-values-lookup",
    },
  };
  return thunk;
};

export const resetTrialsSearch = () => async (dispatch) => {
  dispatch({
    type: RESET_TRIALS_SEARCH,
  });
};

export const resetTrialsRecommended = () => async (dispatch) => {
  dispatch({
    type: RESET_TRIALS_RECOMMENDED,
  });
};
