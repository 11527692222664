import axios from "axios";
import { sec } from "../../security";
import config from "../../auth.config";
const API_ROOT = process.env.REACT_APP_API_ROOT;

//======== Action Types =========

// ***************** METRICS TYPES ***************** //
export const GET_METRICS_REQUEST = "GET_METRICS_REQUEST";
export const GET_METRICS_SUCCESS = "GET_METRICS_SUCCESS";
export const GET_METRICS_FAILURE = "GET_METRICS_FAILURE";

export const RESET_METRICS = "RESET_METRICS";

export const getDashboardDataMultiple = (queries, actAsId) => async (
  dispatch
) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({
    type: GET_METRICS_REQUEST,
  });

  const requests = Object.keys(queries).map((collection) =>
    axios({
      method: "POST",
      url: `${API_ROOT}/app/${collection}/query/multiple`,
      data: queries[collection],
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        Id_Token: id_token.__raw,
        "x-act-as": actAsId || "",
      },
    })
  );

  try {
    const responses = await Promise.all(requests);

    const consolidatedResponses = responses.reduce(
      (accum, value) => ({
        ...accum,
        ...value.data,
      }),
      {}
    );

    dispatch({
      type: GET_METRICS_SUCCESS,
      payload: consolidatedResponses,
    });
  } catch (error) {
    console.log("Error", error);
    dispatch({
      type: GET_METRICS_FAILURE,
      error: "There was an issue fetching the data. Please try again later.",
    });
  }
};

export const resetMetrics = () => async (dispatch) => {
  dispatch({
    type: RESET_METRICS,
  });
};
