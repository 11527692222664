import React, { useContext } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import history from "../Shared/Redux/history";
import { useLocation } from "react-router-dom";
import { Browser } from "@capacitor/browser";

//components
import RecordsStyles from "../../assets/jss/components/RecordsStyles";
import * as userActions from "../User/UserActions";
import { AppContext } from "../../App";

//styling
import {
  withStyles,
  Grid,
  IconButton,
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { showConfirmation } from "../Confirmation/ConfirmationActions";
import { Dialog as CapDialog } from "@capacitor/dialog";

const TrialsDetails = (props) => {
  const { classes } = props;
  const location = useLocation();
  const trial = location.state.trial;
  const { platform, desktop, userDetails } = useContext(AppContext);
  const dispatch = useDispatch();
  const userSavedTrials = useSelector((state) => state.user.userSavedTrials);
  const userSavedTrialsLoading = useSelector(
    (state) => state.user.updateTrialLoading
  );
  const savingTrialLoadingId = useSelector((state) => state.user.loadingID);

  const handleLearnMoreTrial = async () => {
    if (platform === "web") {
      window.open(`https://clinicaltrials.gov/ct2/show/${trial.id}`, "_blank");
    } else {
      await Browser.open({
        url: `https://clinicaltrials.gov/ct2/show/${trial.id}`,
      });
    }
  };

  const handleSaveTrial = () => {
    dispatch(
      userActions?.saveClinicalTrial(
        trial.id,
        userDetails.dependant ? userDetails.id : null
      )
    );
  };

  const handleRemoveTrial = async () => {
    if (platform === "web") {
      dispatch(
        showConfirmation(
          "Remove Saved Clinical Research",
          `Are you sure you want to remove this clinical research?`,
          "removeClinicalTrial",
          trial.id
        )
      );
    } else {
      const { value } = await CapDialog.confirm({
        title: "Remove Saved Clinical Research",
        message: `Are you sure you want to remove this clinical research?`,
        cancelButtonTitle: "Cancel",
        okButtonTitle: "Ok",
      });
      if (value) {
        dispatch(
          userActions?.removeClinicalTrial(
            trial.id,
            userDetails.dependant ? userDetails.id : null
          )
        );
      }
    }
  };

  return (
    <Grid container>
      {(platform !== "web" || (platform === "web" && !desktop)) && (
        <Grid
          item
          xs={12}
          className={classes.backIcon}
          onClick={() => history.goBack()}
        >
          <IconButton aria-label={"trialDetailsBack"}>
            <ChevronLeftIcon
              style={{ fontSize: 40, marginTop: -10 }}
              color={"primary"}
            />
          </IconButton>
        </Grid>
      )}
      <Grid
        item
        xs={12}
        style={{ marginTop: platform === "web" && desktop ? 20 : "70px" }}
      >
        {platform === "web" && desktop ? (
          <Grid container justifyContent={"space-between"}>
            <Grid item onClick={() => history.goBack()}>
              <Button startIcon={<ChevronLeftIcon />}>Back</Button>
            </Grid>
            {userSavedTrialsLoading && savingTrialLoadingId === trial.id ? (
              <IconButton>
                <CircularProgress color={"secondary"} size={20} />
              </IconButton>
            ) : userSavedTrials && userSavedTrials.includes(trial.id) ? (
              <Grid item>
                <Button
                  startIcon={<StarIcon color={"secondary"} />}
                  onClick={handleRemoveTrial}
                >
                  Remove
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  startIcon={<StarBorderIcon color={"secondary"} />}
                  onClick={handleSaveTrial}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        ) : (
          <Grid
            container
            justifyContent={"flex-end"}
            style={{ paddingRight: 30 }}
          >
            {userSavedTrials && userSavedTrials.includes(trial.id) ? (
              <Grid item>
                <Button
                  startIcon={<StarIcon color={"secondary"} />}
                  onClick={handleRemoveTrial}
                >
                  Remove
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  startIcon={<StarBorderIcon color={"secondary"} />}
                  onClick={handleSaveTrial}
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          container
          style={{ display: "inherit" }}
          className={
            platform === "web" && desktop ? null : classes.mainContainer
          }
        >
          {/* Trial Details Card */}
          <Grid item xs={12}>
            <Card className={classes.userCard}>
              <CardContent>
                <Typography
                  variant="h5"
                  color={"primary"}
                  style={{ fontWeight: "bolder" }}
                >
                  {trial.id || "New Trial"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#A7A7A7",
                  }}
                >
                  Study Type: {trial.study_type}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#A7A7A7",
                  }}
                >
                  Phase: {trial.phase || "N/A"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: "#A7A7A7",
                  }}
                >
                  Status: {trial.status}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Record Location Details */}
          <Grid
            item
            xs={12}
            className={classes.recordLocationDetails}
            style={{ marginTop: "20px" }}
          >
            <Typography variant="h5">{trial.location || ""}</Typography>
            <Grid container justifyContent={"space-evenly"}>
              <Grid item xs={12}>
                <Typography>
                  <b>Start Date:</b> {trial.start_date || "N/A"}
                </Typography>
                <Typography>
                  <b>Completed:</b> {trial.completion_date || "N/A"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Trial Official Title */}
          <Grid item xs={12} className={classes.header}>
            <Typography
              color={"secondary"}
              variant={"subtitle1"}
              style={{ fontWeight: "bold" }}
            >
              Official Title
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{trial.title || "TBA"}</Typography>
          </Grid>

          {/* Trial Conditions */}
          <Grid item xs={12} className={classes.header}>
            <Typography
              color={"secondary"}
              variant={"subtitle1"}
              style={{ fontWeight: "bold" }}
            >
              Conditions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {(trial.conditions && trial.conditions.join(", ")) || "N/A"}
            </Typography>
          </Grid>

          {/* Trial Sponsors */}
          <Grid item xs={12} className={classes.header}>
            <Typography
              color={"secondary"}
              variant={"subtitle1"}
              style={{ fontWeight: "bold" }}
            >
              Sponsors
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {(trial.sponsors && trial.sponsors.join(",")) || "N/A"}
            </Typography>
          </Grid>

          {/* Trial Description */}
          <Grid item xs={12} className={classes.header}>
            <Typography
              color={"secondary"}
              variant={"subtitle1"}
              style={{ fontWeight: "bold" }}
            >
              Description
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {trial.description
                ? trial.description.replace(/~/g, " ")
                : "Description coming soon..."}
            </Typography>
          </Grid>

          {/* Eligibility Criteria */}
          <Grid item xs={12} className={classes.header}>
            <Typography
              color={"secondary"}
              variant={"subtitle1"}
              style={{ fontWeight: "bold" }}
            >
              Eligibility Criteria
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.reasonForVisit}>
            {trial.criterias.length > 0 ? (
              trial.criterias.map((t, index) => (
                <Card key={index}>
                  <Grid container justifyContent={"center"}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          variant={"subtitle1"}
                          style={{ fontWeight: "bold" }}
                          color={"primary"}
                        >
                          Gender:
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {t.gender && t.gender !== "NaN" ? t.gender : "N/A"}
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          variant={"subtitle1"}
                          style={{ fontWeight: "bold" }}
                          color={"primary"}
                        >
                          Minimum Age:
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {t.minimum_age && t.minimum_age !== "NaN"
                          ? t.minimum_age
                          : "N/A"}
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          variant={"subtitle1"}
                          style={{ fontWeight: "bold" }}
                          color={"primary"}
                        >
                          Maximum Age:
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {t.maximum_age && t.maximum_age !== "NaN"
                          ? t.maximum_age
                          : "N/A"}
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          variant={"subtitle1"}
                          style={{ fontWeight: "bold" }}
                          color={"primary"}
                        >
                          Population:
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {t.population && t.population !== "NaN"
                          ? t.population.replace(/~/g, " ")
                          : "N/A"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        variant={"subtitle1"}
                        style={{ fontWeight: "bold" }}
                        color={"primary"}
                      >
                        Required Condition:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        Criteria Description:{" "}
                        {t.criteria ? t.criteria.replace(/~/g, " ") : "TBD"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <Card>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={12}>
                    <Typography variant="h6">TBD</Typography>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>

          {/* Facilities */}
          <Grid item xs={12} className={classes.header}>
            <Typography
              color={"secondary"}
              variant={"subtitle1"}
              style={{ fontWeight: "bold" }}
            >
              Facilities
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.reasonForVisit}>
            {trial.facilities && trial.facilities.length > 0 ? (
              trial.facilities.map((t, index) => (
                <Card key={index}>
                  <Grid container justifyContent={"center"}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <Typography
                          variant={"subtitle1"}
                          style={{ fontWeight: "bold" }}
                          color={"primary"}
                        >
                          Name:
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {t.name || "N/A"}
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          variant={"subtitle1"}
                          style={{ fontWeight: "bold" }}
                          color={"primary"}
                        >
                          Location:
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {t.city || ""}, {t.state || ""} {t.zip || ""}{" "}
                        {t.country || ""}
                      </Typography>
                      <Grid item xs={12}>
                        <Typography
                          variant={"subtitle1"}
                          style={{ fontWeight: "bold" }}
                          color={"primary"}
                        >
                          Status:
                        </Typography>
                      </Grid>
                      <Typography
                        style={{
                          textAlign: "left",
                          paddingLeft: "10px",
                        }}
                      >
                        {t.status || "N/A"}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              ))
            ) : (
              <Card>
                <Grid container justifyContent={"center"}>
                  <Grid item xs={12}>
                    <Typography variant="h6">TBD</Typography>
                  </Grid>
                </Grid>
              </Card>
            )}
          </Grid>

          <Grid item xs={12} className={classes.buttonContainer}>
            <Button className={classes.button} onClick={handleLearnMoreTrial}>
              Learn More!
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails,
  };
}

export default connect(mapStateToProps)(
  withStyles(RecordsStyles)(TrialsDetails)
);
