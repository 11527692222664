const ProfileStyles = (theme) => ({
  header: {
    textAlign: "center",
    backgroundColor: "#0b0b0b",
    // width: "100vw",
    height: "190px",
  },
  profilePicContainer: {
    display: "flex",
    justifyContent: "center",
  },
  badge: {
    fill: "#ffffff",
    height: "99%",
    // width: "99%",
  },
  imageUpload: {
    visibility: "hidden",
    position: "absolute",
  },
  name: {
    color: "#F8F8F8",
    fontWeight: "bold",
    fontSize: "18px",
  },
  detailsContainer: {
    padding: "0 7.5vw",
    marginTop: "25px",
  },
  title: {
    fontSize: "25px",
    color: "#0b0b0b",
    borderBottom: "2px #0b0b0b solid",
    // width: "55vw",
    paddingBottom: "5px",
  },
  text: {
    fontWeight: "bold",
    fontSize: "18px",
    color: "#3B3B3B",
  },
  details: {
    color: "#00000061",
  },
  resetPassword: {
    marginTop: "25px",
    "& a": {
      textDecoration: "none",
      "& p": {
        color: "#3F1F99",
        fontSize: "18px",
        fontWeight: "bold",
      },
    },
  },
  logOut: {
    textAlign: "center",
    marginTop: "30px",
  },
  version: {
    textAlign: "center",
    marginTop: "15px",
    marginBottom: "15px",
  },
  container: {
    height: "inherit",
    paddingBottom: 20,
    // paddingTop: 20,
  },
  cardContainer: {
    top: 115,
    right: 24,
    overflow: "auto",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordion-root:before": {
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .MuiAccordion-root": {
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  cardsContainer: {
    overflow: "auto",
  },
  terms: {
    marginTop: "15px",
    "&hover": {
      cursor: "pointer",
    },
  },
  buttonWrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: "#2C205F",
    position: "absolute",
    top: "50%",
    left: "20%",
    marginTop: -9,
    marginLeft: -8,
  },
});

export default ProfileStyles;
