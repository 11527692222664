import { showNotification } from "../Notifications/NotificationsActions";
import axios from "axios";
import { sec } from "../../security";
import config from "../../auth.config";
const API_ROOT = process.env.REACT_APP_API_ROOT;

//======== Action Types =========

// ***************** RECORDS TYPES ***************** //
export const GET_USER_PRIVATE_REQUEST = "GET_USER_PRIVATE_REQUEST";
export const GET_USER_PRIVATE_SUCCESS = "GET_USER_PRIVATE_SUCCESS";
export const GET_USER_PRIVATE_FAILURE = "GET_USER_PRIVATE_FAILURE";

export const UPDATE_RECORDS_REQUEST = "UPDATE_RECORDS_REQUEST";
export const UPDATE_RECORDS_SUCCESS = "UPDATE_RECORDS_SUCCESS";
export const UPDATE_RECORDS_FAILURE = "UPDATE_RECORDS_FAILURE";

export const SEARCH_MEDICATION_LIST_REQUEST = "SEARCH_MEDICATION_LIST_REQUEST";
export const SEARCH_MEDICATION_LIST_SUCCESS = "SEARCH_MEDICATION_LIST_SUCCESS";
export const SEARCH_MEDICATION_LIST_FAILURE = "SEARCH_MEDICATION_LIST_FAILURE";

export const SEARCH_CONDITIONS_LIST_REQUEST = "SEARCH_CONDITIONS_LIST_REQUEST";
export const SEARCH_CONDITIONS_LIST_SUCCESS = "SEARCH_CONDITIONS_LIST_SUCCESS";
export const SEARCH_CONDITIONS_LIST_FAILURE = "SEARCH_CONDITIONS_LIST_FAILURE";

export const RESET_RECORDS = "RESET_RECORDS";

export const getUserRecords = (actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: GET_USER_PRIVATE_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/app/user/private`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
  })
    .then((response) => {
      dispatch({
        type: GET_USER_PRIVATE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_USER_PRIVATE_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const updateUserRecords = (data, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: UPDATE_RECORDS_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/app/medical-records`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
    data,
  })
    .then((response) => {
      dispatch({
        type: UPDATE_RECORDS_SUCCESS,
        payload: response.data,
      });
      dispatch(
        showNotification("Medical Records successfully updated", "success")
      );
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: UPDATE_RECORDS_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const searchMedications = (query) => {
  const thunk = async (dispatch, getState) => {
    dispatch({ type: SEARCH_MEDICATION_LIST_REQUEST });

    return axios({
      method: "GET",
      url: `https://api.fda.gov/drug/ndc.json?search=brand_name:${query}&limit=100`,
    })
      .then((response) => {
        dispatch({
          type: SEARCH_MEDICATION_LIST_SUCCESS,
          payload: {
            data: response.data.results,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SEARCH_MEDICATION_LIST_FAILURE,
        });
      });
  };
  thunk.meta = {
    debounce: {
      time: 500,
      key: "medications-values-lookup",
    },
  };
  return thunk;
};

export const searchConditions = (query) => {
  const thunk = async (dispatch, getState) => {
    const access_token = await sec.getAccessTokenSilently()({
      audience: config.audience,
    });
    const id_token = await sec.getIdTokenClaims()({
      audience: config.audience,
    });
    dispatch({ type: SEARCH_CONDITIONS_LIST_REQUEST });

    return axios({
      method: "GET",
      url: `${API_ROOT}/app/search/conditions?q=${query}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        Id_Token: id_token.__raw,
      },
    })
      .then((response) => {
        dispatch({
          type: SEARCH_CONDITIONS_LIST_SUCCESS,
          payload: {
            data: response.data.items,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: SEARCH_CONDITIONS_LIST_FAILURE,
        });
      });
  };
  thunk.meta = {
    debounce: {
      time: 500,
      key: "conditions-values-lookup",
    },
  };
  return thunk;
};

export const resetRecords = () => async (dispatch) => {
  dispatch({
    type: RESET_RECORDS,
  });
};
