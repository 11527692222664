import axios from "axios";
import { sec } from "../../security";
import config from "../../auth.config";
const API_ROOT = process.env.REACT_APP_API_ROOT;

//======== Action Types =========

// ***************** PAYMENTS TYPES ***************** //
export const GET_PAYMENTS_REQUEST = "GET_PAYMENTS_REQUEST";
export const GET_PAYMENTS_SUCCESS = "GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_FAILURE = "GET_PAYMENTS_FAILURE";

export const REDEEM_REWARDS_REQUEST = "REDEEM_REWARDS_REQUEST";
export const REDEEM_REWARDS_SUCCESS = "REDEEM_REWARDS_SUCCESS";
export const REDEEM_REWARDS_FAILURE = "REDEEM_REWARDS_FAILURE";

export const USER_BALANCE_REQUEST = "USER_BALANCE_REQUEST";
export const USER_BALANCE_SUCCESS = "USER_BALANCE_SUCCESS";
export const USER_BALANCE_FAILURE = "USER_BALANCE_FAILURE";
export const USER_BALANCE_UPDATE = "USER_BALANCE_UPDATE";

export const RESET_PAYMENTS = "RESET_PAYMENTS";
export const RESET_BALANCE = "RESET_BALANCE";

export const getPayments = (skip, limit, sort, direction, q, actAsId) => {
  const thunk = async (dispatch, getState) => {
    const access_token = await sec.getAccessTokenSilently()({
      audience: config.audience,
    });
    const id_token = await sec.getIdTokenClaims()({
      audience: config.audience,
    });
    dispatch({ type: GET_PAYMENTS_REQUEST });

    return axios({
      method: "GET",
      url: `${API_ROOT}/app/payment/my`,
      params: {
        skip,
        limit,
        sort,
        direction,
        q,
      },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json;charset=UTF-8",
        Accept: "application/json",
        Authorization: "Bearer " + access_token,
        Id_Token: id_token.__raw,
        "x-act-as": actAsId || "",
      },
    })
      .then((response) => {
        dispatch({
          type: GET_PAYMENTS_SUCCESS,
          payload: response.data.items,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: GET_PAYMENTS_FAILURE,
        });
      });
  };
  thunk.meta = {
    debounce: {
      time: 500,
      key: "filter-values-lookup",
    },
  };
  return thunk;
};

export const redeemRewards = (data, actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: REDEEM_REWARDS_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/app/redeem`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
    data,
  })
    .then((response) => {
      dispatch({
        type: REDEEM_REWARDS_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: USER_BALANCE_UPDATE,
        payload: {
          amount: response.data.amount,
        },
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: REDEEM_REWARDS_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const getUserBalance = (actAsId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: USER_BALANCE_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/app/user/balance`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
      "x-act-as": actAsId || "",
    },
  })
    .then((response) => {
      dispatch({
        type: USER_BALANCE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: USER_BALANCE_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const resetPayments = () => async (dispatch) => {
  dispatch({
    type: RESET_PAYMENTS,
  });
};

export const resetBalance = () => async (dispatch) => {
  dispatch({
    type: RESET_BALANCE,
  });
};
