import React, { useContext, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";

//components
import Inputs from "../Forms/Inputs";
import TextList from "./TextList";
import AccordionList from "./AccordionList";
import Loader from "../Loader/Loader";
import { statusList } from "../../Trials/helpers/statusHelper";
import { countryList } from "../../Trials/helpers/stateCountryHelper";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../../App";
import PaymentsMetrics from "../../Payments/PaymentsMetrics";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import ListStyles from "../../../assets/jss/components/ListStyles";
import {
  Button,
  Chip,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import utils from "../Utils/utils";

const List = (props) => {
  const {
    classes,
    viewType,
    options,
    optionNames,
    value,
    dropdown,
    styling,
    noResultsMessage,
    placeholder,
    star,
    handleStarClick,
    handleClick,
    handleChange,
    filterOpen,
    setFilterOpen,
    searchValue,
    setSearchValue,
    description,
    loading,
    clearSearch,
    handleScrollUpdate,
    setFilterValue,
    filterValue,
    statusFilterValue,
    setStatusFilterValue,
    optionPrimary,
    listRender,
    quickSearchChips,
    bottomButton,
    bottomButtonText,
    bottomButtonHandleClick,
    loadingMessage,
    handleApplyStatusFilter,
  } = props;
  const { platform, desktop } = useContext(AppContext);
  const [sortBy, setSortBy] = useState("default");
  const [expanded, setExpanded] = useState(false);
  const [openQuickSearch, setOpenQuickSearch] = useState(false);
  let dropdownType, results, potentialResults, participated, current, past;
  const userBalance = useSelector((state) => state.balance.userBalance);
  const location = useLocation();

  const handleChipClick = (value) => {
    setSearchValue(value);
    handleChange({ target: { value: value } });
    setOpenQuickSearch(false);
  };

  //dropdown type on top of page
  switch (dropdown) {
    case "autocomplete":
      dropdownType = (
        <Inputs
          type={"autocomplete"}
          value={value}
          handleClick={handleClick}
          handleChange={handleChange}
          options={options}
          placeholder={placeholder}
        />
      );
      break;
    case "searchbar":
      dropdownType = (
        <div>
          <Inputs
            type={"searchBar"}
            placeholder={placeholder}
            handleChange={handleChange}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            clearSearch={clearSearch}
            desktopFilter={
              platform === "web" && desktop && viewType === "detailedTrialsList"
            }
            setFilterOpen={setFilterOpen}
          />
          {quickSearchChips && quickSearchChips.length > 0 && (
            <Grid
              container
              alignItems={"center"}
              justifyContent={"flex-end"}
              style={{
                paddingRight: 20,
                marginTop: 5,
              }}
            >
              <Grid item>
                <Typography
                  variant={"caption"}
                  color={"primary"}
                  onClick={() => setOpenQuickSearch(!openQuickSearch)}
                >
                  Search My Conditions
                </Typography>
              </Grid>
              <Grid item>
                {openQuickSearch ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </Grid>
              {openQuickSearch && (
                <Grid item xs={12}>
                  <Grid
                    container
                    style={{
                      paddingBottom: 10,
                      marginLeft: 10,
                      marginRight: 10,
                      overflowY: "scroll",
                      maxHeight: 500,
                    }}
                  >
                    {quickSearchChips.map((chip, i) => {
                      return (
                        <Chip
                          key={`chip-${i}`}
                          label={chip}
                          variant={"outlined"}
                          color={"secondary"}
                          style={{ maxWidth: "90%", margin: 2 }}
                          size={"small"}
                          clickable
                          onClick={() => handleChipClick(chip)}
                        />
                      );
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      );
      break;
    default:
      dropdownType = null;
  }

  //checks to see if filter is selcted, if not, returns all options
  if (options && options.length > 0) {
    if (viewType !== "detailedTrialsList") {
      potentialResults = options.filter((option) => {
        if (sortBy === "starred" && option.starred) {
          return option;
        } else if (sortBy === "default") {
          return option;
        }
        return null;
      });
    } else {
      participated = options.filter((o) => o.participated && o.status);
      current = options.filter((o) => o.status && !o.participated);
      past = options.filter((o) => !o.status);
      potentialResults = [participated, current, past];
    }
  } else if (!loading) {
    // no initial results
    // user has no providers/records/messages/etc.
    results = (
      <Typography
        style={{
          paddingLeft: "2vw",
          marginTop: "40px",
          textAlign: "center",
          fontStyle: "italic",
        }}
      >
        {noResultsMessage}
      </Typography>
    );
  }

  //maps through all options filtered/nonfiltered from above
  //checks viewType and maps over all filtered/nonfiltered options
  if (potentialResults && potentialResults.length > 0) {
    results = potentialResults.map((option, index) => {
      if (
        viewType === "list" ||
        viewType === "filter" ||
        viewType === "detailedList"
      ) {
        return (
          <TextList
            key={index}
            index={index}
            handleClick={handleClick}
            option={option}
            optionNames={optionNames}
            star={star}
            handleStarClick={handleStarClick}
            detailed={viewType === "detailedList"}
            description={description || "description"}
            optionPrimary={optionPrimary}
            listRender={listRender}
          />
        );
      } else if (viewType === "detailedTrialsList") {
        let categoryCount = 0;
        potentialResults.map((r) => {
          if (r.length > 0) categoryCount += 1;
          return null;
        });

        return (
          <div key={`detail-${index}`}>
            {option.map((t, index) => {
              return (
                <TextList
                  key={`list-${index}`}
                  index={index}
                  handleClick={handleClick}
                  option={t}
                  optionNames={optionNames}
                  star={star}
                  handleStarClick={handleStarClick}
                  detailed={viewType === "detailedList"}
                  styling={t.participated && t.status ? styling : null}
                  description={description || "description"}
                  listRender={listRender}
                />
              );
            })}
            {index < 2 && option.length > 0 && categoryCount > 1 ? (
              <Divider style={{ margin: "25px 15px" }} />
            ) : null}
          </div>
        );
      } else if (viewType === "accordion") {
        return (
          <AccordionList
            key={index}
            index={index}
            option={option}
            optionNames={optionNames}
            expanded={expanded}
            setExpanded={setExpanded}
            description={description || "description"}
          />
        );
      }
      return null;
    });
  } else if (!loading) {
    // user has no providers/records/messages/etc.
    results = (
      <Grid container justifyContent={"center"}>
        <Grid item>
          <Typography
            style={{
              paddingLeft: "2vw",
              marginTop: "40px",
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            {noResultsMessage}
          </Typography>
        </Grid>
      </Grid>
    );
  }

  let styles;
  if (dropdown === "none") {
    styles = { marginTop: "0", paddingTop: 0, display: "block" };
  } else {
    styles = { display: "flow-root" };
  }

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        className={classes.searchContainer}
        style={{
          marginTop:
            platform === "web" && desktop
              ? 20
              : viewType === "savedTrialsList"
              ? -25
              : 125,
          backgroundColor: "#fff",
        }}
      >
        {dropdownType}
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent={"center"} className={classes.container}>
          <Grid item xs={11} className={classes.cardsContainer}>
            <Grid
              container
              className={classes.cardContainer}
              style={{
                ...styles,
                height: "inherit",
                marginTop:
                  platform === "web" && desktop
                    ? 40
                    : window.location.pathname.includes("help")
                    ? 0
                    : 80,
              }}
              id={"scrollableDiv"}
            >
              {viewType === "filter" && filterOpen ? (
                <Grid container justifyContent={"flex-end"}>
                  <Grid item xs={6}>
                    <FormControl style={{ width: "100%" }}>
                      <Select
                        labelId="filter-select"
                        id="filter-select"
                        value={sortBy}
                        label="Filter"
                        onChange={(e) => setSortBy(e.target.value)}
                      >
                        <MenuItem value={"default"}>
                          <em>Default</em>
                        </MenuItem>
                        <MenuItem value={"starred"}>Starred</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              {viewType === "detailedTrialsList" && filterOpen ? (
                <Grid
                  container
                  justifyContent={"flex-end"}
                  style={{ marginTop: 80 }}
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="filterByCountryLabel">
                        Filter by Country
                      </InputLabel>
                      <Select
                        aria-labelledby="filterByCountryLabel"
                        fullWidth
                        labelId="filter-select"
                        id="filter-select"
                        value={filterValue}
                        label="Country"
                        role={"listbox"}
                        onChange={(e) => setFilterValue(e.target.value)}
                      >
                        <MenuItem value={""}>
                          <em>None</em>
                        </MenuItem>
                        {countryList.map((country, i) => {
                          return (
                            <MenuItem value={country} key={`filter-${i}`}>
                              {country}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl style={{ width: "100%" }}>
                      <InputLabel id="filterByCountryLabel">
                        Filter by Status
                      </InputLabel>
                      <Select
                        aria-labelledby="filterByStatusLabel"
                        fullWidth
                        labelId="filter-select-status"
                        id="filter-select-status"
                        value={statusFilterValue}
                        label="Status"
                        role={"listbox"}
                        onChange={(e) => setStatusFilterValue(e.target.value)}
                        // onBlur={handleApplyStatusFilter}
                        multiple
                        IconComponent={() => (
                          <Button
                            variant={"contained"}
                            color={"primary"}
                            size={"small"}
                            onClick={handleApplyStatusFilter}
                          >
                            Search
                          </Button>
                        )}
                      >
                        {statusList.map((status, i) => {
                          return (
                            <MenuItem value={status} key={`status-filter-${i}`}>
                              {utils.capitalizeString(status)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
              {location && location.pathname === "/rewards" && userBalance && (
                <Grid item style={{ marginTop: 50 }}>
                  <PaymentsMetrics classes={classes} />
                </Grid>
              )}
              <Grid
                item
                style={{
                  paddingTop: !dropdown
                    ? 0
                    : quickSearchChips && !filterOpen
                    ? 80
                    : 20,
                }}
              >
                <InfiniteScroll
                  dataLength={(options && options.length) || 0} //This is important field to render the next data
                  next={handleScrollUpdate}
                  hasMore={true}
                  loader={
                    loading && (
                      <Grid
                        container
                        justifyContent={"center"}
                        style={{
                          marginTop: options && options.length ? 5 : 40,
                          marginBottom: "5vh",
                        }}
                        direction={"column"}
                        alignItems={"center"}
                        alignContent={"center"}
                      >
                        <Grid item>
                          <Loader color={"#E8C658"} size={50} />
                        </Grid>
                        {loadingMessage && (
                          <Grid item>
                            <Typography variant={"caption"}>
                              {loadingMessage}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    )
                  }
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  // scrollableTarget={platform !== 'web' ? "scrollableDiv" : null}
                >
                  {results}
                </InfiniteScroll>
              </Grid>
              {bottomButton && (
                <Grid item xs={12} className={classes.bottomButtonContainer}>
                  <Button
                    className={classes.bottomButton}
                    style={{ backgroundColor: "#E8C658" }}
                    onClick={bottomButtonHandleClick}
                  >
                    {bottomButtonText}
                  </Button>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withStyles(ListStyles)(List);
