import React from "react";
import NotificationStyles from "../../assets/jss/components/NotificationStyles";

//mui
import { Snackbar, SnackbarContent } from "@material-ui/core";
import WifiOffIcon from "@material-ui/icons/WifiOff";
import { withStyles } from "@material-ui/core/styles";

const OfflineNotification = (props) => {
  const { classes, online } = props;

  return (
    <Snackbar
      open={typeof online === "boolean" && !online}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <SnackbarContent
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes["message-error"]}>
            <WifiOffIcon className={classes.icon} />
            No internet connection found
          </span>
        }
      />
    </Snackbar>
  );
};

export default withStyles(NotificationStyles)(OfflineNotification);
