import * as types from "./TrialsActions";
import { REMOVE_SAVED_TRIAL_SUCCESS } from "../User/UserActions";

//reducer for shared actions
export const trials = (
  state = { searchTrials: [], savedTrials: [] },
  action
) => {
  switch (action.type) {
    case types.SEARCH_TRIALS_REQUEST:
      return { ...state, searchTrialsLoading: true };

    case types.SAVED_TRIALS_REQUEST:
      return { ...state, savedTrialsLoading: true };

    case types.RECOMMENDED_TRIALS_REQUEST:
      return { ...state, recommendedTrialsLoading: true };

    case types.SEARCH_TRIALS_SUCCESS:
      return {
        ...state,
        searchTrials: state.searchTrials.concat(action.payload.data),
        searchCountryFilter: action.payload.country,
        searchTrialsLoading: false,
      };

    case types.FETCH_SAVED_SUCCESS:
      return {
        ...state,
        savedTrials: action.payload.data,
        savedTrialsLoading: false,
      };

    case REMOVE_SAVED_TRIAL_SUCCESS:
      return {
        ...state,
        savedTrials: [
          ...state.savedTrials.filter(
            (item: any) => item.id !== action.payload
          ),
        ],
      };

    case types.RECOMMENDED_TRIALS_SUCCESS:
      return {
        ...state,
        recommendedTrials: action.payload.data,
        recommendedTrialsLoading: false,
      };

    case types.SEARCH_TRIALS_FAILURE:
      return { ...state, searchTrialsLoading: false };

    case types.RESET_TRIALS_SEARCH:
      return { searchTrials: [], savedTrials: [] };

    case types.RESET_TRIALS_RECOMMENDED:
      return { ...state, recommendedTrials: [] };

    default:
      return state;
  }
};
