import * as types from "./PaymentsActions";

//reducer for payment actions
export const payments = (state = { myPayments: [] }, action) => {
  switch (action.type) {
    case types.GET_PAYMENTS_REQUEST:
      return { ...state, myPaymentsLoading: true };

    case types.GET_PAYMENTS_SUCCESS:
      return {
        ...state,
        myPayments: action.payload,
        myPaymentsLoading: false,
      };

    case types.GET_PAYMENTS_FAILURE:
      return { ...state, myPaymentsLoading: false };

    case types.RESET_PAYMENTS:
      return { myPayments: [] };

    default:
      return state;
  }
};

//reducer for balance actions
export const balance = (state = {}, action) => {
  switch (action.type) {
    case types.REDEEM_REWARDS_REQUEST:
      return { ...state, redeemLoading: true };

    case types.REDEEM_REWARDS_SUCCESS:
      return {
        ...state,
        redeemStatus: action.payload,
        redeemLoading: false,
      };

    case types.REDEEM_REWARDS_FAILURE:
      return { ...state, redeemLoading: false };

    case types.USER_BALANCE_REQUEST:
      return { ...state, userBalanceLoading: true };

    case types.USER_BALANCE_SUCCESS:
      return {
        ...state,
        userBalance: action.payload,
        userBalanceLoading: false,
      };

    case types.USER_BALANCE_UPDATE:
      return {
        ...state,
        userBalance: {
          ...state.userBalance,
          balance: state.userBalance.balance - action.payload.amount,
          redeems: state.userBalance.redeems + action.payload.amount,
        },
      };

    case types.USER_BALANCE_FAILURE:
      return { ...state, userBalanceLoading: false };

    case types.RESET_BALANCE:
      return {};

    default:
      return state;
  }
};
