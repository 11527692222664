import React from "react";

// mui
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const MetricsCount = (props) => {
  const { title, value } = props;
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100%" }}
    >
      <Grid item>
        <Typography variant={"body1"} color={"textSecondary"}>
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant={"h5"} color={"primary"}>
          $
          {Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(value)}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MetricsCount;
