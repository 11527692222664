import React from "react";

//styling
import { TextField, MenuItem, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../../../assets/jss/components/OnboardingStyles";

const Select = (props) => {
  const {
    classes,
    id,
    value,
    label,
    handleChange,
    passedValue,
    className,
    style,
    options,
    helperText,
    required,
  } = props;

  return (
    <div>
      <TextField
        fullWidth
        id={id}
        select
        value={value}
        label={label}
        variant="outlined"
        onChange={(e) => handleChange(e, passedValue)}
        className={className ? classes.formInputLarge : ""}
        style={style}
        required={required}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      {helperText && <Typography variant={"caption"}>{helperText}</Typography>}
    </div>
  );
};

export default withStyles(OnboardingStyles)(Select);
