import React, { useEffect } from "react";
import PropTypes from "prop-types";
import NotificationStyles from "../../assets/jss/components/NotificationStyles";
import { Toast } from "@capacitor/toast";

//redux additions
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as notificationActions from "./NotificationsActions";

//material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

export const Notification = (props) => {
  const { actions, notification } = props;

  useEffect(() => {
    if (notification && notification.visible) {
      showNotificationToast();
    }
  }, [notification]);

  const showNotificationToast = async () => {
    await Toast.show({
      text:
        notification && notification.message && notification.message.toString(),
      duration: "short",
    });
    actions.clearNotification();
  };

  return null;
};

Notification.propTypes = {
  notification: PropTypes.object,
};

Notification.defaultProps = {
  notification: {
    message: null,
    visible: false,
    notificationType: "info",
    update: false,
  },
};

export function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(notificationActions, dispatch) };
}

//middleware
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(NotificationStyles)(Notification));
