import React from "react";

//styling
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../../../assets/jss/components/OnboardingStyles";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

const BooleanInput = (props) => {
  const { value, handleChange, name, label, required } = props;

  return (
    <FormControl component="fieldset" required={required || false}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        row
        aria-label="position"
        name={name}
        value={
          typeof value === "boolean" && value === true
            ? "yes"
            : typeof value === "boolean" && value === false
            ? "no"
            : undefined
        }
        onChange={handleChange}
      >
        <FormControlLabel
          value={"yes"}
          control={<Radio color="primary" />}
          label="Yes"
        />
        <FormControlLabel
          value={"no"}
          control={<Radio color="primary" />}
          label="No"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default withStyles(OnboardingStyles)(BooleanInput);
