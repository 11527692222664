import { TrendingUpTwoTone } from "@material-ui/icons";
import * as types from "./MetricsActions";

//reducer for shared actions
export const metrics = (state = {}, action) => {
  switch (action.type) {
    case types.GET_METRICS_REQUEST:
      return { ...state, loading: TrendingUpTwoTone };

    case types.GET_METRICS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        loading: false,
      };

    case types.GET_METRICS_FAILURE:
      return { ...state, loading: false };

    case types.RESET_METRICS:
      return {};

    default:
      return state;
  }
};
