import React, { useContext, useEffect, useState } from "react";

//components
import AutocompleteInput from "../Shared/Forms/Fields/AutocompleteInput";
import { bindActionCreators } from "redux";
import * as recordsActions from "../Records/RecordsActions";
import { connect, useDispatch } from "react-redux";
import { neuroblastomaFields } from "./helpers/NeuroblastomaFields";
import BooleanInput from "../Shared/Forms/Fields/BooleanInput";
import SharedStyles from "../../assets/jss/components/SharedStyles";
import DropdownInput from "../Shared/Forms/Fields/DropdownInput";

// mui
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import { withStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import { Chip, TextField, Tooltip } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import FormLabel from "@material-ui/core/FormLabel";
import { showConfirmation } from "../Confirmation/ConfirmationActions";
import { Dialog as CapDialog } from "@capacitor/dialog";
import { AppContext } from "../../App";

const ConditionsForm = (props) => {
  const {
    recordsActions,
    conditionsList,
    conditionsListLoading,
    conditions,
    setRecords,
    records,
    classes,
    conditionsNA,
    setConditionsNA,
    dependentView,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState(conditions || []);
  const [neuroblastomaData, setNeuroblastomaData] = useState(records);
  const { platform } = useContext(AppContext);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!conditionsListLoading && conditionsList) {
      setOptions(conditionsList);
    }
  }, [conditionsList, conditionsListLoading]);

  useEffect(() => {
    setRecords({
      ...records,
      ...neuroblastomaData,
      conditions: selected,
    });
  }, [selected, neuroblastomaData]);

  useEffect(() => {
    if (
      !neuroblastomaData.geneticTesting &&
      neuroblastomaData.geneMutations &&
      neuroblastomaData.geneMutations.length > 0
    ) {
      setNeuroblastomaData({
        ...neuroblastomaData,
        geneMutations: [],
      });
    }
  }, [neuroblastomaData]);

  const handleClose = () => {
    setOptions([]);
    setSearchValue("");
  };

  const handleClick = (e) => {
    e.persist();
    const index = e.target.dataset.optionIndex;
    if (index) {
      const selectedCondition = options[index].name;
      if (selectedCondition) {
        setSelected([...selected, selectedCondition]);
      }
      if (selectedCondition.includes("Neuroblastoma")) {
        setNeuroblastomaData({
          ...neuroblastomaData,
          isNeuroBlastoma: true,
        });
      }
    }
    handleClose();
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (value === "") {
      return;
    } else {
      recordsActions.searchConditions(value);
    }
    setSearchValue(e.target.value);
  };

  const handleDropdownChange = (e, field) => {
    let value = e.target.value;
    if (field.name === "currentProcedures") {
      value = [value];
    }
    setNeuroblastomaData({
      ...neuroblastomaData,
      [field.name]: value,
    });
  };

  const handleMultiChange = (e, newValue, field) => {
    let value = newValue;
    if (
      newValue.includes("None of the above") &&
      Array.isArray(newValue) &&
      newValue.length > 1
    ) {
      if (newValue[newValue.length - 1] === "None of the above") {
        value = ["None of the above"];
      } else {
        const index = newValue.indexOf("None of the above");
        value.splice(index, 1);
      }
    }
    setNeuroblastomaData({
      ...neuroblastomaData,
      [field.name]: value,
    });
  };

  const handleBooleanChange = (e, field) => {
    let value = e.target.value;
    if (value === "yes") {
      value = true;
    } else {
      value = false;
    }
    setNeuroblastomaData({
      ...neuroblastomaData,
      [field.name]: value,
    });
  };

  const handleResponse = (value, item, i) => {
    if (value) {
      const selectedCopy = [...selected];
      selectedCopy.splice(i, 1);
      setSelected(selectedCopy);
      if (item.includes("Neuroblastoma")) {
        setNeuroblastomaData({
          isNeuroBlastoma: false,
        });
      }
    } else {
      return;
    }
  };

  const handleRemoveCondition = async (item, i) => {
    if (platform === "web") {
      dispatch(
        showConfirmation(
          "Remove Saved Condition",
          `Are you sure you want to remove this condition?`,
          "removeRecordItem",
          (e) => handleResponse(e, item, i)
        )
      );
    } else {
      const { value } = await CapDialog.confirm({
        title: "Remove Saved Condition",
        message: `Are you sure you want to remove this condition?`,
        cancelButtonTitle: "Cancel",
        okButtonTitle: "Ok",
      });
      if (value) {
        handleResponse(true, item, i);
      }
    }
  };

  return (
    <Grid container justifyContent={"center"} spacing={3}>
      <Grid item xs={12} style={{ backgroundColor: "#EBEBEB" }}>
        <Typography>
          Please add any condition(s){" "}
          {dependentView
            ? "your dependent has that have "
            : "you have that has "}{" "}
          been identified by {dependentView ? "their " : "your "}
          doctor(s).
        </Typography>
      </Grid>
      <Grid item xs={11} md={10}>
        <Typography className={classes.title} style={{ marginTop: "20px" }}>
          {dependentView ? "My Dependents " : "My "} Conditions
        </Typography>
        {((records && records.conditions && records.conditions.length === 0) ||
          !records.conditions) && (
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={conditionsNA}
                    onChange={() => setConditionsNA(!conditionsNA)}
                    name="conditionsNA"
                    color="primary"
                  />
                }
                label={`${
                  dependentView ? "My dependent does not " : "I do not "
                } have any conditions`}
              />
            </FormControl>
          </Grid>
        )}
        <List>
          {selected.map((item, i) => {
            return (
              <div key={`conditions-list-${i}`}>
                <ListItem>
                  <ListItemText primary={item} />
                  <ListItemSecondaryAction>
                    <Tooltip title={"Delete Condition"}>
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleRemoveCondition(item, i)}
                      >
                        <DeleteIcon color={"secondary"} />
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </div>
            );
          })}
        </List>
      </Grid>
      {!conditionsNA && (
        <Grid item xs={11} md={10}>
          <AutocompleteInput
            searchValue={searchValue}
            handleClick={handleClick}
            handleChange={handleChange}
            handleClose={handleClose}
            options={options.map((option) => option.name)}
            placeholder={"Search to add condition"}
            loading={conditionsListLoading}
          />
        </Grid>
      )}
      <Grid item xs={11} md={10}>
        {selected.some((item) => item.includes("Neuroblastoma")) && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography
                className={classes.title}
                style={{ marginTop: "20px" }}
              >
                Additional Neuroblastoma Questions
              </Typography>
            </Grid>
            {neuroblastomaFields(dependentView).map((field, i) => {
              if (field.show) {
                if (field.type === "dropdown") {
                  return (
                    <Grid item xs={12} key={`neuroblastoma-select-${i}`}>
                      {field.multiSelect ? (
                        <FormControl
                          component="fieldset"
                          required={field.required || false}
                          fullWidth
                          disabled={
                            field.name === "geneMutations" &&
                            !records.geneticTesting
                          }
                        >
                          <FormLabel component="legend">
                            {field.question}
                          </FormLabel>
                          <Autocomplete
                            multiple
                            id="tags-filled"
                            options={field.options}
                            fullWidth
                            freeSolo
                            disabled={
                              field.name === "geneMutations" &&
                              !records.geneticTesting
                            }
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={`chip-${i}`}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            value={neuroblastomaData[field.name] || []}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder={"Specify other..."}
                                helperText={
                                  "Select all that apply, or if not listed please specify by typing in answer above"
                                }
                              />
                            )}
                            onChange={(e, newValue) =>
                              handleMultiChange(e, newValue, field)
                            }
                          />
                        </FormControl>
                      ) : field.freeText ? (
                        <FormControl
                          component="fieldset"
                          required={field.required || false}
                          fullWidth
                        >
                          <FormLabel component="legend">
                            {field.question}
                          </FormLabel>
                          <Autocomplete
                            options={field.options}
                            freeSolo
                            value={
                              (neuroblastomaData[field.name] &&
                                neuroblastomaData[field.name][0]) ||
                              []
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder={"Specify other..."}
                                onChange={(e) => handleDropdownChange(e, field)}
                              />
                            )}
                            required={true}
                            onChange={(e, newValue) =>
                              handleDropdownChange(
                                { target: { value: newValue } },
                                field
                              )
                            }
                          />
                        </FormControl>
                      ) : (
                        <DropdownInput
                          field={field}
                          handleChange={handleDropdownChange}
                          value={neuroblastomaData[field.name] || []}
                          required={true}
                          currentData={neuroblastomaData}
                        />
                      )}
                    </Grid>
                  );
                } else if (field.type === "boolean") {
                  let value = neuroblastomaData[field.name];
                  return (
                    <Grid item xs={12} key={`neuroblastoma-boolean-${i}`}>
                      <BooleanInput
                        name={field.name}
                        value={value}
                        handleChange={(e) => handleBooleanChange(e, field)}
                        label={field.question}
                        required={true}
                      />
                    </Grid>
                  );
                }
              }
            })}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export function mapStateToProps(state) {
  return {
    conditionsList: state.records.conditionsList,
    conditionsListLoading: state.records.conditionsListLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recordsActions: bindActionCreators(recordsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(SharedStyles)(ConditionsForm));
