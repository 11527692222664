import React from "react";

//components
import TextFieldInput from "./Fields/TextFieldInput";
import DateInput from "./Fields/DatePicker";
import Select from "./Fields/SelectInput";

//material-ui
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../../assets/jss/components/OnboardingStyles";

const FormBuilder = (props) => {
  const { classes, form, userInfo, handleChange, selectedDate, error } = props;

  return (
    <Grid container className={classes.formContainer}>
      {form.map((field, index) => {
        //checks to see if nestedValue is nested again
        let nestedValue, split;
        if (field.passedValue.includes(".")) {
          split = field.passedValue.split(".");
          nestedValue = { ...userInfo };
          for (let i = 0; i < split.length; i++) {
            nestedValue = nestedValue && nestedValue[split[i]];
          }
        } else {
          nestedValue = userInfo[field.passedValue];
        }

        if (field.name === "text") {
          return (
            <Grid item xs={field.gridSize || 12} key={index}>
              <TextFieldInput
                id={field.passedValue}
                label={field.label}
                style={field.style}
                type={field.type}
                mask={field.mask}
                className={field.className}
                handleChange={(e, label) => handleChange(e, label)}
                passedValue={field.passedValue}
                value={
                  userInfo[split ? split[0] : field.passedValue] && nestedValue
                }
                error={error}
                required={field.required}
              />
            </Grid>
          );
        } else if (field.name === "date") {
          return (
            <Grid item xs={field.gridSize || 12} key={index}>
              <DateInput
                id={field.passedValue}
                value={
                  userInfo[split ? split[0] : field.passedValue] && nestedValue
                }
                label={field.label}
                className={
                  selectedDate === null
                    ? classes.formDOBInput
                    : classes.formInputLarge
                }
                handleChange={(e, label) => handleChange(e, label)}
                passedValue={field.passedValue}
                required={field.required}
              />
            </Grid>
          );
        } else if (field.name === "dropdown") {
          return (
            <Grid item xs={field.gridSize || 12} key={index}>
              <Select
                id={field.passedValue}
                value={
                  (userInfo[split ? split[0] : field.passedValue] &&
                    nestedValue) ||
                  null
                }
                label={field.label}
                handleChange={(e, label) => handleChange(e, label)}
                passedValue={field.passedValue}
                className={field.className}
                style={field.style}
                options={field.options}
                helperText={field.helperText}
                required={field.required}
              />
            </Grid>
          );
        } else return null;
      })}
    </Grid>
  );
};

export default withStyles(OnboardingStyles)(FormBuilder);
