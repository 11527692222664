const NotificationStyles = (theme) => ({
  snackBar: {
    padding: "0px 10px",
    backgroundColor: "white",
    borderStyle: "solid",
    borderWidth: "1px",
    width: "678.8px",
  },
  success: {
    borderColor: "#03c4c8",
  },
  error: {
    borderColor: "#ff3362",
  },
  warning: {
    borderColor: "#ffc12a",
  },
  info: {
    borderColor: "#304075",
  },
  icon: {
    fontSize: 20,
    margin: theme.spacing(1),
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  updateMessage: {
    display: "flex",
    alignItems: "center",
  },
  "message-error": {
    display: "flex",
    alignItems: "center",
    color: "#ff3362",
  },
  "message-warning": {
    display: "flex",
    alignItems: "center",
    color: "#ffc12a",
  },
  "message-info": {
    display: "flex",
    alignItems: "center",
    color: "#304075",
  },
  "message-success": {
    display: "flex",
    alignItems: "center",
    color: "#03c4c8",
  },
  margin: {
    margin: theme.spacing(1),
  },
  "closeIcon-error": {
    color: "#ff3362",
  },
  "closeIcon-warning": {
    color: "#ffc12a",
  },
  "closeIcon-info": {
    color: "#304075",
  },
  "closeIcon-success": {
    color: "#03c4c8",
  },
});

export default NotificationStyles;
