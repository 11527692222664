import React, { useContext, useState } from "react";
import PropTypes from "prop-types";

//component and helper imports
import NavBarStyles from "../../assets/jss/components/NavBarStyles";
import Sidebar from "../Sidebar/Sidebar";
import { AppContext } from "../../App";
import { colours } from "../Dependents/helpers/dependentColours";
import { useSelector } from "react-redux";

//material-ui components
import {
  ButtonGroup,
  IconButton,
  SwipeableDrawer,
  AppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

// icons
import listIcon from "../../assets/img/list_icon.svg";
import filterIcon from "../../assets/img/filter_icon.svg";
import menuIcon from "../../assets/img/menu_icon.svg";
import editIcon from "../../assets/img/edit_icon.svg";
import SearchIcon from "@material-ui/icons/Search";

export const NavBar = (props) => {
  const {
    classes,
    header,
    viewType,
    setViewType,
    filterOpen,
    setFilterOpen,
    searchOpen,
    setSearchOpen,
    editing,
    setEditing,
  } = props;
  const { logoutUserTokens, userDetails } = useContext(AppContext);
  const [left, setLeft] = useState(false);
  const dependents = useSelector((state) => state.dependents.dependents);
  let icon;

  switch (viewType) {
    case "grid":
      icon = (
        <IconButton
          onClick={() => setViewType("list")}
          style={{ marginInlineStart: "auto", color: "#0b0b0b" }}
        >
          <img src={listIcon} alt="Change grid type icon" />
        </IconButton>
      );
      break;
    case "filter":
      icon = (
        <IconButton
          onClick={() => setFilterOpen(!filterOpen)}
          style={{ marginInlineStart: "auto", color: "#0b0b0b" }}
          aria-label={"filterIcon"}
        >
          <img src={filterIcon} alt="Filter icon" />
        </IconButton>
      );
      break;
    case "searchFilter":
      icon = (
        <ButtonGroup style={{ marginInlineStart: "auto" }}>
          <IconButton
            onClick={() => setSearchOpen(!searchOpen)}
            aria-label={"search"}
          >
            <SearchIcon style={{ fill: "#0b0b0b" }} />
          </IconButton>
          <IconButton onClick={() => setFilterOpen(!filterOpen)}>
            <img src={filterIcon} alt="Filter icon" />
          </IconButton>
        </ButtonGroup>
      );
      break;
    case "edit":
      icon = (
        <IconButton
          onClick={() => setEditing(true)}
          style={{ marginInlineStart: "auto", color: "#0b0b0b" }}
        >
          <img src={editIcon} alt="Edit icon" />
        </IconButton>
      );
      break;
    default:
      icon = null;
  }

  return (
    <div className={classes.containerStyle}>
      <AppBar
        position={"fixed"}
        color={"default"}
        className={classes.overRideShadow}
        style={{
          zIndex: 3,
          backgroundColor: userDetails.dependant
            ? colours[
                dependents
                  ? dependents.findIndex((x) => x.id === userDetails.id)
                  : 0
              ]
            : "#fff",
        }}
      >
        <Toolbar>
          {viewType === "profileEditing" ||
          viewType === "trialsDetail" ||
          viewType === "recordEditing" ||
          viewType === "messageDetail" ||
          viewType === "providersDetail" ? null : (
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => setLeft(true)}
            >
              <img src={menuIcon} style={{ height: "20px" }} alt="menu icon" />
            </IconButton>
          )}
          <Typography
            data-testid={"navbar-header"}
            className={classes.menuHeader}
            style={
              viewType === "profileEditing" ||
              viewType === "recordEditing" ||
              viewType === "trialsDetail" ||
              viewType === "messageDetail" ||
              viewType === "providersDetail"
                ? { marginLeft: "48px", marginTop: "4px" }
                : null
            }
          >
            {header}
          </Typography>
          <SwipeableDrawer /*navbar styling wrapper*/
            open={left}
            onClose={() => setLeft(false)}
            onOpen={() => setLeft(true)}
          >
            <div
              aria-label="drawer"
              tabIndex={0}
              role="button"
              onClick={() => setLeft(false)}
              onKeyDown={() => setLeft(false)}
              style={{ height: "100%" }}
            >
              <Sidebar logoutUserTokens={logoutUserTokens} />
            </div>
          </SwipeableDrawer>
          {!editing || viewType !== "basic" ? icon : null}
        </Toolbar>
      </AppBar>
    </div>
  );
};

NavBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(NavBarStyles)(NavBar);
