import { Grid, Typography, Button } from "@material-ui/core";
import React, { useContext } from "react";
import { AppContext } from "../../App";
import image from "../../assets/img/403.png";
import history from "./Redux/history";
import NavBar from "../Navbar/NavBar";
import DesktopNavBar from "../Navbar/DesktopNavBar";

export const RoleCheck = ({
  roles,
  deny,
  children,
  header,
  viewType,
  filterOpen,
  setFilterOpen,
  setEditing,
}) => {
  const { userDetails, platform, logoutUserTokens, desktop } = useContext(
    AppContext
  );
  let isAllowed = true;

  if (typeof roles === "string") {
    isAllowed = deny ? userDetails.role !== roles : userDetails.role === roles;
  } else {
    isAllowed = roles.some((r) =>
      deny ? userDetails.role !== r : userDetails.role === r
    );
  }

  const navigateTo = (e, link) => {
    if (e) {
      e.preventDefault();
    }
    history.push(link);
    return false;
  };

  return isAllowed ? (
    <React.Fragment>
      {platform === "web" && desktop ? (
        <DesktopNavBar>{children}</DesktopNavBar>
      ) : (
        <div>
          <NavBar
            header={header}
            viewType={viewType}
            filterOpen={filterOpen}
            setFilterOpen={setFilterOpen}
            setEditing={setEditing || null}
          />
          {children}
        </div>
      )}
    </React.Fragment>
  ) : (
    <Grid
      container
      justifyContent="center"
      spacing={2}
      style={{
        marginTop: 20,
        paddingRight: 5,
        paddingLeft: 5,
        maxWidth: "100%",
      }}
    >
      <Grid item>
        <Typography variant="h1" color="primary" align="center">
          403
        </Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item>
        <Typography variant="h4" color="primary" align="center">
          Access Denied
        </Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item>
        <Typography variant="body1" align="center">
          Sorry, but you don&apos;t have permission to access this page. Check
          with an administrator.
        </Typography>
        <Typography variant="body1" align="center">
          You can contact us if needed in the{" "}
          <a href="/faqs" onClick={(e) => navigateTo(e, "/faqs")}>
            Help
          </a>{" "}
          section by creating a new question if you do not find the answer you
          are looking for.
        </Typography>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item>
        <img src={image} alt="forbidden" />
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item>
        <Button
          variant={"contained"}
          color={"primary"}
          onClick={logoutUserTokens}
        >
          Log Out
        </Button>
      </Grid>
    </Grid>
  );
};
