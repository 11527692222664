import { showNotification } from "../Notifications/NotificationsActions";
import axios from "axios";
import { sec } from "../../security";
import config from "../../auth.config";
const API_ROOT = process.env.REACT_APP_API_ROOT;

//======== Action Types =========

// ***************** DEPENDENTS TYPES ***************** //
export const GET_DEPENDENTS_REQUEST = "GET_DEPENDENTS_REQUEST";
export const GET_DEPENDENTS_SUCCESS = "GET_DEPENDENTS_SUCCESS";
export const GET_DEPENDENTS_FAILURE = "GET_DEPENDENTS_FAILURE";

export const ADD_DEPENDENTS_REQUEST = "ADD_DEPENDENTS_REQUEST";
export const ADD_DEPENDENTS_SUCCESS = "ADD_DEPENDENTS_SUCCESS";
export const ADD_DEPENDENTS_FAILURE = "ADD_DEPENDENTS_FAILURE";

export const UPDATE_DEPENDENTS_REQUEST = "UPDATE_DEPENDENTS_REQUEST";
export const UPDATE_DEPENDENTS_SUCCESS = "UPDATE_DEPENDENTS_SUCCESS";
export const UPDATE_DEPENDENTS_FAILURE = "UPDATE_DEPENDENTS_FAILURE";

export const DELETE_DEPENDENTS_REQUEST = "DELETE_DEPENDENTS_REQUEST";
export const DELETE_DEPENDENTS_SUCCESS = "DELETE_DEPENDENTS_SUCCESS";
export const DELETE_DEPENDENTS_FAILURE = "DELETE_DEPENDENTS_FAILURE";

export const RESET_DEPENDENTS = "RESET_DEPENDENTS";

export const getDependents = (userId) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: GET_DEPENDENTS_REQUEST });
  return axios({
    method: "GET",
    url: `${API_ROOT}/app/user/dependants`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
  })
    .then((response) => {
      const sortedUsers = response.data.sort((a, b) => {
        const result = a.lastName.localeCompare(b.lastName);
        return result !== 0 ? result : a.firstName.localeCompare(b.firstName);
      });
      dispatch({
        type: GET_DEPENDENTS_SUCCESS,
        payload: sortedUsers,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: GET_DEPENDENTS_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const addDependent = (dependentData) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: ADD_DEPENDENTS_REQUEST });
  return axios({
    method: "POST",
    url: `${API_ROOT}/app/user/dependants`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
    data: dependentData,
  })
    .then((response) => {
      dispatch({
        type: ADD_DEPENDENTS_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: ADD_DEPENDENTS_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const updateDependent = (id) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: UPDATE_DEPENDENTS_REQUEST });
  return axios({
    method: "PUT",
    url: `${API_ROOT}/app/user/dependants/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
  })
    .then((response) => {
      dispatch({
        type: UPDATE_DEPENDENTS_SUCCESS,
        payload: {
          data: response.data,
          id,
        },
      });
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: UPDATE_DEPENDENTS_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const deleteDependent = (id) => async (dispatch) => {
  const access_token = await sec.getAccessTokenSilently()({
    audience: config.audience,
  });
  const id_token = await sec.getIdTokenClaims()({
    audience: config.audience,
  });
  dispatch({ type: DELETE_DEPENDENTS_REQUEST });
  return axios({
    method: "DELETE",
    url: `${API_ROOT}/app/user/dependants/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json;charset=UTF-8",
      Accept: "application/json",
      Authorization: "Bearer " + access_token,
      Id_Token: id_token.__raw,
    },
  })
    .then((response) => {
      dispatch({
        type: DELETE_DEPENDENTS_SUCCESS,
        payload: {
          data: response.data,
          id,
        },
      });
      dispatch(showNotification("Dependent successfully removed", "success"));
    })
    .catch((error) => {
      console.log(error);
      dispatch({
        type: DELETE_DEPENDENTS_FAILURE,
        error: error.response.data.message,
      });
    });
};

export const resetDependents = () => async (dispatch) => {
  dispatch({
    type: RESET_DEPENDENTS,
  });
};
