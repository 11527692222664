import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

//styling
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { AppContext } from "../../../../App";

const DateInput = (props) => {
  const {
    value,
    label,
    className,
    passedValue,
    handleChange,
    required,
  } = props;
  const [dateValue, setDateValue] = useState("");
  const [dependentView, setDependentView] = useState(false);
  const maxDate = moment().subtract(18, "years"); //subtracts 18 years from the current date
  const { userDetails } = useContext(AppContext);

  useEffect(() => {
    if (window.location.pathname === "/dependents") {
      setDependentView(true);
    }
  }, []);

  useEffect(() => {
    if (value) {
      setDateValue(new Date(value));
    }
  }, [value]);

  const theme = createTheme({
    overrides: {
      MuiPickersToolbar: {
        toolbar: {
          backgroundColor: "#8F3749",
        },
      },
      MuiPickersDay: {
        daySelected: {
          backgroundColor: "#8F3749",
        },
        current: {
          color: "#8F3749",
        },
      },
    },
  });

  class LocalizedUtils extends MomentUtils {
    getYearRange(start, end) {
      const startDate = this.moment(end).startOf("year");
      const endDate = this.moment(start).endOf("year");
      const years = [];

      let current = startDate;
      while (current.isAfter(endDate)) {
        years.push(current);
        current = current.clone().subtract(1, "year");
      }

      return years;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={LocalizedUtils}>
        <DatePicker
          label={label}
          inputVariant="outlined"
          format="MM/DD/YYYY"
          openTo="year"
          value={dateValue}
          onChange={(e) =>
            handleChange(
              moment(e).format("yyyy-MM-DDTHH:mm:ss.SSS+00:00"),
              passedValue
            )
          }
          className={className}
          disableFuture={label !== "Please add the end date"}
          fullWidth
          maxDate={
            label === "Please add the end date"
              ? new Date(new Date().setFullYear(new Date().getFullYear() + 10))
              : dependentView || userDetails.dependant
              ? Date.now()
              : maxDate._d
          }
          emptyLabel={""}
          required={required}
        />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default DateInput;
