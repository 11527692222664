import React, { useEffect, useState } from "react";

//components
import Onboarding from "../components/Onboarding/Onboarding";
import { steps } from "../components/Onboarding/helpers/OnboardingHelpers";

const OnboardingView = (props) => {
  const {
    setFirstLogin,
    closeDependentDialog,
    handleAcceptedTc,
    setDependentData,
    setOnboardingStep,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [dependentView, setDependentView] = useState(false);

  useEffect(() => {
    if (window.location.pathname === "/dependents") {
      setActiveStep(4);
      setDependentView(true);
    }
  }, []);

  useEffect(() => {
    if (dependentView) {
      setOnboardingStep(activeStep);
    }
  }, [activeStep, dependentView]);

  const handleStepChange = (step) => {
    if (step !== 3) {
      setActiveStep(step);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Onboarding
      setFirstLogin={setFirstLogin}
      steps={steps}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
      handleStepChange={handleStepChange}
      handleNext={handleNext}
      handleBack={handleBack}
      closeDependentDialog={closeDependentDialog}
      handleAcceptedTc={handleAcceptedTc}
      setDependentData={setDependentData}
    />
  );
};

export default OnboardingView;
