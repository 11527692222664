import React from "react";

//components
import { TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";
import InputStyles from "../../../../assets/jss/components/InputStyles";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AutocompleteInput = (props) => {
  const {
    classes,
    searchValue,
    placeholder,
    handleChange,
    handleClick,
    handleClose,
    options,
    loading,
    customRender,
    getOptionLabel,
    handleCompleteChange,
    helperText,
  } = props;

  return (
    <Autocomplete
      id="free-solo-demo"
      value={searchValue}
      selectOnFocus
      blurOnSelect
      disablePortal
      closeIcon={<CloseIcon />}
      classes={{
        popperDisablePortal: classes.searchPopper,
      }}
      onChange={handleCompleteChange}
      onClose={handleClick}
      options={options}
      getOptionLabel={getOptionLabel}
      noOptionsText={"No Results Found"}
      loading={loading}
      renderOption={customRender}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={placeholder}
          className={classes.searchBar}
          onChange={(e, value) => handleChange(e, value)}
          onClick={handleClose}
          helperText={helperText || ""}
        />
      )}
    />
  );
};

export default withStyles(InputStyles)(AutocompleteInput);
