import React, { useEffect, useState } from "react";

//styling
import { MenuItem, Typography, Select } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../../../assets/jss/components/OnboardingStyles";
import Input from "@material-ui/core/Input";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Checkbox from "@material-ui/core/Checkbox";
import utils from "../../Utils/utils";

const SelectInput = (props) => {
  const { value, handleChange, field, required, currentData } = props;

  return (
    <div>
      <FormControl fullWidth required={required || false}>
        <FormLabel component="legend">{field.question}</FormLabel>
        <Select
          labelId="demo-mutiple-name-label"
          id={field.name}
          multiple={field.multiSelect}
          value={value}
          onChange={(e) => handleChange(e, field)}
          input={<Input />}
          renderValue={field.multiSelect && ((selected) => selected.join(", "))}
        >
          {field.options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {field.multiSelect && (
                <Checkbox checked={value.indexOf(option) > -1} />
              )}
              {option === "UNDECIDED"
                ? "I am not sure"
                : utils.capitalizeString(option)}
            </MenuItem>
          ))}
        </Select>
        {field.helperText && (
          <Typography variant={"caption"}>{field.helperText}</Typography>
        )}
      </FormControl>
    </div>
  );
};

export default withStyles(OnboardingStyles)(SelectInput);
