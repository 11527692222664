import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import config from "./auth.config";

import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { defaultStore } from "./components/Shared/Redux/stores/defaultStore";

// Redux
import history from "./components/Shared/Redux/history";

// Components
import App from "./App";
import { Capacitor } from "@capacitor/core";

const Auth = () => {
  const platform = Capacitor.getPlatform();
  // A function that routes the user to the right place
  // after login
  const onRedirectCallback = (appState) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  if (!platform) return <div>Loading...</div>;
  return (
    <div>
      <Auth0Provider
        domain={config.domain}
        clientId={config.clientId}
        audience={config.audience}
        redirectUri={
          platform === "web" ? window.location.origin : config.redirectUri
        }
        scope={config.scope}
        cacheLocation={"localstorage"}
        onRedirectCallback={platform === "web" && onRedirectCallback}
        useRefreshTokens={true}
      >
        <Provider store={defaultStore}>
          <Router history={history}>
            <App />
          </Router>
        </Provider>
      </Auth0Provider>
    </div>
  );
};

export default Auth;
