export const colours = [
  "#DBD1ED",
  "#DDEDEA",
  "#DAEAF6",
  "#F5D5CB",
  "#F3DDF2",
  "#D7ECD9",
  "#E8DFF5",
  "#FCE1E4",
  "#FCF4DD",
];
