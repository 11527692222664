import * as types from "./RecordsActions";

//reducer for shared actions
export const records = (state = {}, action) => {
  switch (action.type) {
    case types.GET_USER_PRIVATE_REQUEST:
      return { ...state, myRecordsLoading: true };

    case types.GET_USER_PRIVATE_SUCCESS:
      return {
        ...state,
        myRecords: action.payload,
        myRecordsLoading: false,
      };

    case types.GET_USER_PRIVATE_FAILURE:
      return {
        ...state,
        myRecordsLoading: false,
        myRecords: {},
        myRecordsError: action.error,
      };

    case types.UPDATE_RECORDS_REQUEST:
      return { ...state, myRecordsLoading: true };

    case types.UPDATE_RECORDS_SUCCESS:
      return {
        ...state,
        myRecords: action.payload,
        myRecordsLoading: false,
      };

    case types.UPDATE_RECORDS_FAILURE:
      return {
        ...state,
        myRecordsLoading: false,
        myRecordsError: action.error,
      };

    case types.SEARCH_MEDICATION_LIST_REQUEST:
      return { ...state, medicationList: [], medicationListLoading: true };

    case types.SEARCH_MEDICATION_LIST_SUCCESS:
      return {
        ...state,
        medicationList: action.payload.data,
        medicationListLoading: false,
      };

    case types.SEARCH_MEDICATION_LIST_FAILURE:
      return {
        ...state,
        medicationListLoading: false,
        medicationListError: action.error,
      };

    case types.SEARCH_CONDITIONS_LIST_REQUEST:
      return { ...state, conditionsList: [], conditionsListLoading: true };

    case types.SEARCH_CONDITIONS_LIST_SUCCESS:
      return {
        ...state,
        conditionsList: action.payload.data,
        conditionsListLoading: false,
      };

    case types.SEARCH_CONDITIONS_LIST_FAILURE:
      return {
        ...state,
        conditionsListLoading: false,
        conditionsListError: action.error,
      };

    case types.RESET_RECORDS:
      return {};

    default:
      return state;
  }
};
