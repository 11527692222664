const SidebarStyles = {
  listItem: {
    marginRight: "60px",
    paddingLeft: "20px",
  },
  linkStyle: {
    textDecoration: "none",
    color: "#000",
  },
  divider: {
    borderBottom: "1px solid #E0E0E0",
    width: "100%",
    margin: "auto",
  },
  sideBar: {
    minHeight: "100%",
    maxHeight: "100%",
    position: "relative",
    paddingBottom: "5rem",
  },
  logo: {
    height: "25px",
    marginTop: "20px",
  },
  footer: {
    width: "100%",
    marginBlockStart: "auto",
    textAlign: "center",
    bottom: 0,
    marginBottom: "15px",
    position: "absolute",
  },
  text: {
    fontSize: "18px",
    fontWeight: "bold",
    paddingTop: "2px",
    margin: "0 0 0 -15px",
    fontFamily: "Avenir Next",
    fontStyle: "italic",
  },
};

export default SidebarStyles;
