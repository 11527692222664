//actions & payloads for notifications
export const types = {
  DISPLAY_SNACKBAR_MESSAGE: "DISPLAY_SNACKBAR_MESSAGE",
  CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",
};

export const showNotification = (message, notificationType, update) => ({
  type: types.DISPLAY_SNACKBAR_MESSAGE,
  message,
  notificationType,
  update,
});

export const clearNotification = () => ({
  type: types.CLEAR_NOTIFICATION,
});
