import React, { useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import history from "../Shared/Redux/history";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as userActions from "../User/UserActions";
import * as dependentActions from "../Dependents/DependentsActions";

//components
import SidebarStyles from "../../assets/jss/components/SidebarStyles";
import { colours } from "../Dependents/helpers/dependentColours";
import utils from "../Shared/Utils/utils";
import { AppContext } from "../../App";
import logo from "../../assets/img/red_logo.png";

//auth0
import { useAuth0 } from "@auth0/auth0-react";

// material-ui
import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

// icons
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import StarsIcon from "@material-ui/icons/Stars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserMd } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  large: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
}));

//creating sidebar component
export const SideBar = (props) => {
  const {
    classes,
    userDetails,
    userProfilePic,
    userActions,
    dependentActions,
    dependents,
  } = props;
  const avatarStyle = useStyles();
  const { user } = useAuth0();
  const { online, platform, desktop } = useContext(AppContext);
  const location = useLocation();

  useEffect(() => {
    if (userDetails && userDetails.email && !dependents) {
      dependentActions.getDependents(userDetails.email);
    }
  }, []);

  const handleViewSwitch = (dependentId) => {
    userActions.getUser(userDetails.creator, dependentId);
    userActions.getUserProfilePic(userDetails.creator, dependentId);
    history.push("/");
  };

  const pages = [
    {
      url: "/",
      show: true,
      icon: (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15 2.5L2.5 15H5V26.25H25V15H27.5L15
                2.5ZM15 6.25L22.5 13.75V23.75H7.5V13.75L15
                6.25Z"
            fill={location.pathname === "/" ? "#E8C658" : "#0b0b0b"}
          />
          <rect
            x="13"
            y="16"
            width="4"
            height="9"
            fill={location.pathname === "/" ? "#E8C658" : "#0b0b0b"}
            strokeWidth="2"
          />
        </svg>
      ),
      text: "Dashboard",
    },
    {
      url: "/records",
      show: true,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 13.5H12V16.5H6V13.5Z"
            fill={location.pathname === "/records" ? "#E8C658" : "#0b0b0b"}
          />
          <path
            d="M24 3H22.5V0H7.5V3H4.5V4.875L3.6
                6H1.5V8.625L0 10.5V24H18L24 16.5V3ZM3
                7.5H15V10.5H3V7.5ZM16.5
                22.5H1.5V12H16.5V22.5ZM18
                10.5H16.5V6H6V4.5H18V10.5ZM21 6.75L19.5
                8.625V3H9V1.5H21V6.75Z"
            fill={location.pathname === "/records" ? "#E8C658" : "#0b0b0b"}
          />
        </svg>
      ),
      text: "My Records",
    },
    // {
    //   url: "/rewards",
    //   show: true,
    //   icon: <MonetizationOnIcon />,
    //   text: "My Rewards",
    // },
    {
      url: "/clinical-research",
      show: true,
      icon: <FontAwesomeIcon icon={faUserMd} size="2x" />,
      text: "Clinical Research",
    },
    // {
    //   url: "/dependents",
    //   icon: <PeopleAltIcon />,
    //   text: "Dependents",
    //   show: !userDetails.dependant,
    // },
    {
      url: "/faqs",
      icon: <HelpOutlineOutlinedIcon />,
      text: "FAQs",
      show: true,
    },
    {
      url: "/settings",
      icon: <SettingsOutlinedIcon />,
      text: "Settings",
      show: true,
    },
  ];

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      alignItems="flex-start"
      className={classes.sideBar}
    >
      <Grid
        item
        style={{
          marginTop: platform === "web" && desktop ? 90 : 10,
          marginLeft: 10,
          maxHeight: "calc(100vh - 80px)",
          overflow: "auto",
        }}
      >
        <Grid container justifyContent={"center"}>
          <Grid item xs={11}>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Grid item>
                <Avatar
                  style={{ marginBottom: "5px" }}
                  className={avatarStyle.large}
                  src={(userProfilePic && userProfilePic.src) || ""}
                  alt={(userProfilePic && userProfilePic.alt) || ""}
                />
              </Grid>
              {/*<Tooltip*/}
              {/*  title={*/}
              {/*    "Points are earned by different interactions with the HealthToken application. More details on redemption options coming soon."*/}
              {/*  }*/}
              {/*  open={showTooltip}*/}
              {/*  onOpen={() => setShowTooltip(true)}*/}
              {/*  onClose={() => setShowTooltip(false)}*/}
              {/*  leaveTouchDelay={3000}*/}
              {/*>*/}
              {/*  <Grid*/}
              {/*    item*/}
              {/*    style={{*/}
              {/*      backgroundColor: "#DBD1ED",*/}
              {/*      paddingLeft: 10,*/}
              {/*      paddingRight: 10,*/}
              {/*      borderRadius: 15,*/}
              {/*    }}*/}
              {/*    onClick={(e) => {*/}
              {/*      e.stopPropagation();*/}
              {/*      setShowTooltip(!showTooltip);*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    <Grid container direction={"column"} alignItems={"center"}>*/}
              {/*      <Grid item>*/}
              {/*        <Typography variant={"caption"}>Your Points</Typography>*/}
              {/*      </Grid>*/}
              {/*      <Grid item>*/}
              {/*        <Typography variant={"h6"} color={"primary"}>*/}
              {/*          {userBalance && userBalance.payments}*/}
              {/*        </Typography>*/}
              {/*      </Grid>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*</Tooltip>*/}
            </Grid>
            <Typography>
              {" "}
              {userDetails.firstName
                ? userDetails.firstName + " " + userDetails.lastName
                : user.nickname}
            </Typography>
            {/*<FormControl>*/}
            {/*  <Select*/}
            {/*    labelId="demo-simple-select-label"*/}
            {/*    id="demo-simple-select"*/}
            {/*    value={userDetails.id}*/}
            {/*    style={{*/}
            {/*      color: "#0b0b0b",*/}
            {/*      fontWeight: "bold",*/}
            {/*      fontSize: 18,*/}
            {/*      display: "inline-block",*/}
            {/*    }}*/}
            {/*    disableUnderline*/}
            {/*    fullWidth*/}
            {/*  >*/}
            {/*    {!userDetails.dependant && (*/}
            {/*      <MenuItem value={userDetails.id}>*/}
            {/*        <Grid container alignItems={"center"}>*/}
            {/*          <Grid item>*/}
            {/*            <StarsIcon*/}
            {/*              fontSize="small"*/}
            {/*              style={{ marginRight: 10 }}*/}
            {/*            />*/}
            {/*          </Grid>*/}
            {/*          <Grid item>*/}
            {/*            <Typography>*/}
            {/*              {userDetails.firstName*/}
            {/*                ? userDetails.firstName + " " + userDetails.lastName*/}
            {/*                : user.nickname}*/}
            {/*            </Typography>*/}
            {/*          </Grid>*/}
            {/*        </Grid>*/}
            {/*      </MenuItem>*/}
            {/*    )}*/}
            {/*    {userDetails.dependant && (*/}
            {/*      <MenuItem*/}
            {/*        value={"guardian"}*/}
            {/*        onClick={() => handleViewSwitch(null)}*/}
            {/*      >*/}
            {/*        <Grid container alignItems={"center"}>*/}
            {/*          <Grid item>*/}
            {/*            <StarsIcon*/}
            {/*              fontSize="small"*/}
            {/*              style={{ marginRight: 10 }}*/}
            {/*            />*/}
            {/*          </Grid>*/}
            {/*          <Grid item>*/}
            {/*            <Typography>Switch Back</Typography>*/}
            {/*          </Grid>*/}
            {/*        </Grid>*/}
            {/*      </MenuItem>*/}
            {/*    )}*/}
            {/*    {dependents && dependents.length > 0 ? (*/}
            {/*      dependents.map((dependent, i) => {*/}
            {/*        if (utils.calculateAge(dependent.birthDate) <= 17) {*/}
            {/*          return (*/}
            {/*            <MenuItem*/}
            {/*              key={dependent.id}*/}
            {/*              value={dependent.id}*/}
            {/*              onClick={() => handleViewSwitch(dependent.id)}*/}
            {/*              disabled={*/}
            {/*                utils.calculateAge(dependent.birthDate) > 17*/}
            {/*              }*/}
            {/*            >*/}
            {/*              <Grid container alignItems={"center"}>*/}
            {/*                <Grid item>*/}
            {/*                  <FiberManualRecordIcon*/}
            {/*                    fontSize="small"*/}
            {/*                    style={{ color: colours[i], marginRight: 10 }}*/}
            {/*                  />*/}
            {/*                </Grid>*/}
            {/*                <Grid item>*/}
            {/*                  <Typography>*/}
            {/*                    {dependent.firstName + " " + dependent.lastName}*/}
            {/*                  </Typography>*/}
            {/*                </Grid>*/}
            {/*              </Grid>*/}
            {/*            </MenuItem>*/}
            {/*          );*/}
            {/*        } else {*/}
            {/*          return null;*/}
            {/*        }*/}
            {/*      })*/}
            {/*    ) : (*/}
            {/*      <MenuItem value={"none"} style={{ color: "gray" }}>*/}
            {/*        <em>No Dependents</em>*/}
            {/*      </MenuItem>*/}
            {/*    )}*/}
            {/*  </Select>*/}
            {/*</FormControl>*/}
            <Typography
              variant={"subtitle1"}
              style={{
                marginBottom: "15px",
                fontWeight: "lighter",
              }}
            >
              {userDetails.primaryAddress && userDetails.primaryAddress.city
                ? userDetails.primaryAddress.city +
                  ", " +
                  userDetails.primaryAddress.state
                : user.email}
            </Typography>
          </Grid>
        </Grid>
        <div className={classes.divider} />
        <div>
          <List>
            {pages.map((page) => {
              if (page.show) {
                return (
                  <Link
                    key={page.url}
                    to={page.url}
                    className={classes.linkStyle}
                  >
                    <ListItem
                      className={classes.listItem}
                      disableGutters
                      style={
                        location.pathname === page.url
                          ? { color: "#E8C658" }
                          : { color: "#0b0b0b" }
                      }
                    >
                      <ListItemIcon style={{ color: "inherit" }}>
                        {page.icon}
                      </ListItemIcon>
                      <ListItemText>
                        <p className={classes.text}>{page.text}</p>
                      </ListItemText>
                    </ListItem>
                  </Link>
                );
              }
            })}
          </List>
        </div>
      </Grid>
      <div className={classes.footer}>
        <Typography
          variant={"h5"}
          style={{ fontWeight: "bold", color: "#E8C658" }}
        >
          HealthToken AI
        </Typography>
      </div>
    </Grid>
  );
};

export function mapStateToProps(state) {
  return {
    userDetails: state.user.userDetails,
    userProfilePic: state.user.userProfilePic,
    dependents: state.dependents.dependents,
    getDependentsLoading: state.dependents.getDependentsLoading,
    getDependentsError: state.dependents.getDependentsError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    dependentActions: bindActionCreators(dependentActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(SidebarStyles)(SideBar));
