import React, { useContext, useEffect, useState } from "react";

// redux
import * as recordsActions from "./RecordsActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

//components
import { AppContext } from "../../App";
import utils from "../Shared/Utils/utils";
import SharedStyles from "../../assets/jss/components/SharedStyles";
import Loader from "../Shared/Loader/Loader";
import ConditionsForm from "./ConditionsForm";
import MedicationsForm from "./MedicationsForm";
import ClinicalTrialsForm from "./ClinicalTrialsForm";

//material-ui
import { Button, Grid, IconButton, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  faHeartbeat,
  faPills,
  faUserMd,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { useLocation } from "react-router-dom";

const Records = (props) => {
  const {
    classes,
    recordsActions,
    myRecords,
    myRecordsLoading,
    editing,
    setEditing,
    type,
    setOnboardingRecords,
    setDisableContinue,
  } = props;
  const { online, userDetails, platform, desktop } = useContext(AppContext);
  const [expanded, setExpanded] = useState(false);
  const [records, setRecords] = useState({ medications: [], conditions: [] });
  const [conditionsNA, setConditionsNA] = useState(false);
  const [medicationsNA, setMedicationsNA] = useState(false);
  const [disableSave, setDisableSave] = useState(true);
  const [dependentView, setDependentView] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (
      (userDetails && userDetails.dependant) ||
      (type === "onboarding" && location.pathname === "/dependents")
    ) {
      setDependentView(true);
    } else {
      setDependentView(false);
    }
  }, [userDetails]);

  useEffect(() => {
    return () => {
      recordsActions.resetRecords();
    };
  }, [online, recordsActions]);

  useEffect(() => {
    if (online) {
      recordsActions.getUserRecords(
        userDetails.dependant ? userDetails.id : null
      );
    }
  }, [online, userDetails, recordsActions]);

  useEffect(() => {
    if (myRecords && type !== "onboarding") {
      setRecords(myRecords);
      if (!myRecords.conditions || myRecords.conditions.length === 0) {
        setConditionsNA(true);
      }
      if (!myRecords.medications || myRecords.medications.length === 0) {
        setMedicationsNA(true);
      }
    }
  }, [myRecords, type]);

  useEffect(() => {
    if (type === "onboarding") {
      setOnboardingRecords(records);
      setDisableContinue(disableSave);
    }
  }, [records, disableSave]);

  // check if all fields are filled in
  useEffect(() => {
    if (
      (!records.conditions && !conditionsNA) ||
      (records &&
        records.conditions &&
        records.conditions.length === 0 &&
        !conditionsNA)
    ) {
      setDisableSave(true);
    } else if (
      records &&
      records.medications &&
      records.medications.length === 0 &&
      !medicationsNA
    ) {
      setDisableSave(true);
    } else if (records && !records.clinicalTrial) {
      setDisableSave(true);
    } else if (
      records &&
      records.clinicalTrialEnrollment !== true &&
      records.clinicalTrialEnrollment !== false
    ) {
      setDisableSave(true);
    } else if (
      records &&
      records.conditions &&
      records.conditions.some((item) => item.includes("Neuroblastoma")) &&
      (!records.neuroBlastomaStage ||
        (records.tumorAreas && records.tumorAreas.length === 0) ||
        (records.currentProcedures && records.currentProcedures.length === 0) ||
        (records.recentProcedures && records.recentProcedures.length === 0) ||
        (records.geneticTesting !== true && records.geneticTesting !== false) ||
        (records.geneticTesting &&
          (!records.geneMutations || records.geneMutations.length === 0)) ||
        (records.hasRelapsed !== true && records.hasRelapsed !== false))
    ) {
      setDisableSave(true);
    } else {
      setDisableSave(false);
    }
  }, [records, medicationsNA, conditionsNA]);

  const handleCancelEdit = () => {
    setEditing(false);
    setRecords(myRecords);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleEditView = () => {
    setEditing(true);
    setExpanded("panel1");
  };

  const handleSaveRecords = () => {
    setEditing(false);
    setExpanded("");
    recordsActions.updateUserRecords(
      records,
      userDetails.dependant ? userDetails.id : null
    );
  };

  if (myRecordsLoading !== false) {
    return (
      <Grid
        container
        alignContent="center"
        justifyContent="center"
        className={classes.sharedMain}
        style={{ marginTop: 80 }}
      >
        <Grid item>
          <Loader color={"#E8C658"} size={50} />
        </Grid>
      </Grid>
    );
  }

  const createList = (type, items) => {
    if (items && items.length) {
      return (
        <List>
          {items.map((item, i) => {
            let primaryText = item;
            let secondaryText = "";
            if (type === "medications") {
              primaryText = item.productName;
              secondaryText = `Product NDC: ${item.productNdc}`;
            }
            return (
              <div key={`${type}-list-${i}`}>
                <ListItem>
                  <ListItemText
                    primary={primaryText}
                    secondary={secondaryText}
                  />
                </ListItem>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </div>
            );
          })}
        </List>
      );
    }
  };

  const createNeuroblastomaItems = (records) => {
    const itemsList = {
      "Neuroblastoma Stage": records.neuroBlastomaStage || "N/A",
      "Patient is deceased":
        typeof records.deceased == "boolean" ? records.deceased : "N/A",
      "Patient has relapsed":
        typeof records.hasRelapsed == "boolean" ? records.hasRelapsed : "N/A",
      "Patient is currently in chemotherapy treatment":
        typeof records.onChemo == "boolean" ? records.onChemo : "N/A",
      "Patience has received radiation as part of their treatment":
        typeof records.receivedRadiation == "boolean"
          ? records.receivedRadiation
          : "N/A",
      "Current Tumor Areas":
        (records.tumorAreas && records.tumorAreas.join(", ")) || "N/A",
      "Surgeries or Procedures Done in Last 12 Months":
        (records.recentProcedures && records.recentProcedures.join(", ")) ||
        "N/A",
      "Current Surgery or Procedure Being Done":
        (records.currentProcedures && records.currentProcedures.join(", ")) ||
        "N/A",
      "Completed Genetic Testing?":
        typeof records.geneticTesting == "boolean"
          ? records.geneticTesting
          : "N/A",
      "Gene Mutation":
        (records.geneMutations && records.geneMutations.join(", ")) || "N/A",
    };
    const entries = Object.entries(itemsList);
    return (
      <Grid item xs={12}>
        <Typography className={classes.title} style={{ marginTop: "20px" }}>
          Neuroblastoma Details
        </Typography>
        <List>
          {entries.map((item, i) => {
            const primary = item[0];
            let secondary = item[1];
            if (typeof secondary == "boolean") {
              if (secondary === true) {
                secondary = "Yes";
              } else {
                secondary = "No";
              }
            }
            if (!dependentView && primary === "Patient is deceased")
              return null;
            return (
              <div key={`neurblastoma-list-${i}`}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography color={"primary"}>{primary}</Typography>
                    }
                    secondary={secondary}
                  />
                </ListItem>
                {i + 1 < entries.length && (
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                )}
              </div>
            );
          })}
        </List>
      </Grid>
    );
  };

  return (
    <Grid
      container
      justifyContent={platform === "web" && desktop ? "flex-end" : "center"}
      style={{ position: "relative" }}
    >
      {platform === "web" && desktop && !editing && (
        <Grid item>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={handleEditView}
          >
            Update My Records
          </Button>
        </Grid>
      )}
      {platform === "web" && editing && desktop && type !== "onboarding" && (
        <Grid item>
          <Button
            variant={"contained"}
            color={"secondary"}
            onClick={handleCancelEdit}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button
            variant={"contained"}
            color={"primary"}
            onClick={handleSaveRecords}
            disabled={disableSave}
          >
            Save My Records
          </Button>
        </Grid>
      )}
      {((platform === "web" && !desktop) || platform !== "web") &&
        editing &&
        type !== "onboarding" && (
          <Grid
            item
            xs={12}
            style={{
              zIndex: "9999999",
              position: "fixed",
              top: "8px",
              left: "10px",
            }}
            onClick={() => setEditing(false)}
          >
            <IconButton aria-label={"disable.edit"}>
              <ChevronLeftIcon
                style={{ fontSize: 40, marginTop: -10 }}
                color={"primary"}
              />
            </IconButton>
          </Grid>
        )}
      {/*{type === "onboarding" && (*/}
      {/*  <Grid item xs={12} style={{ backgroundColor: "#EBEBEB", padding: 20 }}>*/}
      {/*    <Grid*/}
      {/*      container*/}
      {/*      justifyContent={"center"}*/}
      {/*      style={{ textAlign: "center" }}*/}
      {/*    >*/}
      {/*      <Grid item xs={12} md={8}>*/}
      {/*        <Typography*/}
      {/*          variant={"h6"}*/}
      {/*          style={{ fontWeight: "bolder" }}*/}
      {/*          color={"primary"}*/}
      {/*        >*/}
      {/*          Provide your medical records to receive a $5 reward!*/}
      {/*        </Typography>*/}
      {/*      </Grid>*/}
      {/*      <Grid item xs={12} md={6}>*/}
      {/*        <Typography*/}
      {/*          variant={"caption"}*/}
      {/*          color={"secondary"}*/}
      {/*          style={{ fontStyle: "italic" }}*/}
      {/*        >*/}
      {/*          HealthToken will confirm successful completion of the medical*/}
      {/*          record form before issuing the reward. Upon verification of your*/}
      {/*          onboarding information, the reward will appear in your account*/}
      {/*          within 3-5 business days.*/}
      {/*        </Typography>*/}
      {/*      </Grid>*/}
      {/*    </Grid>*/}
      {/*  </Grid>*/}
      {/*)}*/}
      <Grid item xs={12} style={{ marginTop: 30, marginBottom: 80 }}>
        <div className={classes.root}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faHeartbeat}
                    size="2x"
                    style={{ color: "#E8C658" }}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.heading}>
                    Conditions
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {editing ? (
                <ConditionsForm
                  conditions={records.conditions}
                  setRecords={setRecords}
                  records={records}
                  setConditionsNA={setConditionsNA}
                  conditionsNA={conditionsNA}
                  dependentView={dependentView}
                />
              ) : (
                <Grid container justifyContent={"center"}>
                  {myRecords &&
                  myRecords.conditions &&
                  myRecords.conditions.length ? (
                    <Grid item xs={11} md={10}>
                      <Grid container justifyContent={"center"}>
                        <Grid item xs={12}>
                          <Typography
                            className={classes.title}
                            style={{ marginTop: "20px" }}
                          >
                            Reported Conditions
                          </Typography>
                          {createList("conditions", records.conditions)}
                        </Grid>
                        {myRecords.conditions.some((item) =>
                          item.includes("Neuroblastoma")
                        ) && createNeuroblastomaItems(records)}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item>No conditions recorded</Grid>
                  )}
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faPills}
                    size="2x"
                    style={{ color: "#E8C658" }}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.heading}>
                    Medications
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {editing ? (
                <MedicationsForm
                  medications={records.medications}
                  setRecords={setRecords}
                  records={records}
                  medicationsNA={medicationsNA}
                  setMedicationsNA={setMedicationsNA}
                  dependentView={dependentView}
                />
              ) : (
                <Grid container justifyContent={"center"}>
                  {myRecords &&
                  myRecords.medications &&
                  myRecords.medications.length ? (
                    <Grid item xs={11} md={10}>
                      <Typography
                        className={classes.title}
                        style={{ marginTop: "20px" }}
                      >
                        Reported Medications
                      </Typography>
                      {createList("medications", records.medications)}
                    </Grid>
                  ) : (
                    <Grid item>No medications recorded</Grid>
                  )}
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
            elevation={0}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Grid container alignItems={"center"} spacing={2}>
                <Grid item>
                  <FontAwesomeIcon
                    icon={faUserMd}
                    size="2x"
                    style={{ color: "#E8C658" }}
                  />
                </Grid>
                <Grid item>
                  <Typography className={classes.heading}>
                    Clinical Research
                  </Typography>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {editing ? (
                <ClinicalTrialsForm
                  setRecords={setRecords}
                  records={records}
                  dependentView={dependentView}
                />
              ) : (
                <Grid container justifyContent={"center"}>
                  <Grid item xs={11} md={10} style={{ marginBottom: 20 }}>
                    <Typography
                      className={classes.title}
                      style={{ marginTop: "20px" }}
                    >
                      Current Clinical Research Participation
                    </Typography>
                  </Grid>
                  {myRecords && myRecords.clinicalTrial === "YES" && (
                    <Grid item xs={12}>
                      <Typography style={{ textAlign: "center" }}>
                        {dependentView ? "Your dependent is " : "You are "}{" "}
                        currently enrolled in a clinical research with an end
                        date of {utils.formatDate(records.clinicalTrialEndDate)}
                        .
                      </Typography>
                    </Grid>
                  )}
                  {myRecords && myRecords.clinicalTrial === "NO" && (
                    <Grid item xs={12}>
                      <Typography style={{ textAlign: "center" }}>
                        {dependentView ? "Your dependent is " : "You are "} not
                        currently enrolled in a clinical research. Please update
                        if this information is incorrect.
                      </Typography>
                    </Grid>
                  )}
                  {myRecords && myRecords.clinicalTrial === "UNDECIDED" && (
                    <Grid item xs={12}>
                      <Typography style={{ textAlign: "center" }}>
                        Unsure
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={11} md={10} style={{ marginBottom: 20 }}>
                    <Typography
                      className={classes.title}
                      style={{ marginTop: "20px" }}
                    >
                      Enroll in Clinical Research Participation?
                    </Typography>
                  </Grid>
                  {myRecords && myRecords.clinicalTrialEnrollment ? (
                    <Grid item>
                      <Typography>
                        Yes, {dependentView ? "your dependent is " : "you are "}{" "}
                        interested in potential clinical research.
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid item>
                      <Typography>
                        No, {dependentView ? "your dependent is " : "you are "}{" "}
                        not currently interested in enrolling in potential
                        clinical research.
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              )}
            </AccordionDetails>
          </Accordion>
        </div>
      </Grid>
      {editing && type !== "onboarding" && !desktop && (
        <Grid
          item
          style={{
            textAlign: "center",
            bottom: 0,
            position: "absolute",
          }}
        >
          <Button
            style={{
              backgroundColor: disableSave ? "gainsboro" : "#3F1F99",
              color: "#ffffff",
              borderRadius: "0px",
              padding: "15px 30px 15px 30px",
              fontWeight: "bold",
              marginTop: "5vw",
              marginBottom: "5vw",
              width: "78vw",
            }}
            disabled={disableSave}
            onClick={handleSaveRecords}
          >
            Save
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export function mapStateToProps(state) {
  return {
    myRecords: state.records.myRecords,
    myRecordsLoading: state.records.myRecordsLoading,
    myRecordsError: state.records.myRecordsError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recordsActions: bindActionCreators(recordsActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(SharedStyles)(Records));
