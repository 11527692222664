import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import { Dialog as CapDialog } from "@capacitor/dialog";

//material-ui
import { withStyles } from "@material-ui/core/styles";
import OnboardingStyles from "../../assets/jss/components/OnboardingStyles";
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

//components
import FormBuilder from "../Shared/Forms/FormBuilder";
import EmergencyForm from "./EmergencyForm";
import Terms from "./Terms";
import { userInfoForm } from "../Shared/Forms/helpers/userInfoMapper";
import { AppContext } from "../../App";
import history from "../Shared/Redux/history";
import Records from "../Records/Records";
import { useSelector } from "react-redux";

const OnboardingPage = (props) => {
  const {
    classes,
    step,
    setFirstLogin,
    setActiveStep,
    userDetails,
    handleDependentCreation,
    closeDependentDialog,
    handleConsent,
    dependents,
    recordsActions,
    handleAcceptedTc,
  } = props;
  const { platform, logoutUserTokens } = useContext(AppContext);
  const [name, setName] = useState("");
  const [userInfo, setUserInfo] = useState(userDetails);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [checkedDependent, setCheckedDependent] = useState(false);
  const [disabled, setDisabled] = useState(step.page < 3);
  const [bgColor, setBgColor] = useState({ backgroundColor: "gainsboro" });
  const [selectedDate, setSelectedDate] = useState(null);
  const [consentDate, setConsentDate] = useState(null);
  const [error, setError] = useState({});
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [dependentView, setDependentView] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const [onboardingRecords, setOnboardingRecords] = useState({});
  const addDependentsLoading = useSelector(
    (state) => state.dependents.addDependentsLoading
  );
  let required;

  useEffect(() => {
    if (step.page === 1) {
      showAgeAlert();
    }
  }, [step]);

  useEffect(() => {
    if (window.location.pathname === "/dependents") {
      setDependentView(true);
    }
  }, []);

  useEffect(() => {
    if (dependentView) {
      setUserInfo({
        ...userInfo,
        firstName: "",
        lastName: "",
        gender: "",
        birthDate: Date.now(),
        ethnicity: "",
      });
    }
  }, [dependentView]);

  const showAgeAlert = async () => {
    if (platform === "web") {
      setOpenConfirmationDialog(true);
    } else {
      if (window.location.pathname === "/dependents") {
        const { value } = await CapDialog.confirm({
          title: "Consent Verification",
          message: `You must have permission or assent from the dependent to register them for the purpose of this app. Refer to the FAQs to learn more.`,
          cancelButtonTitle: "View FAQs",
          okButtonTitle: "I have consent",
        });
        if (!value) {
          setOpenConfirmationDialog(false);
          history.push("/faqs");
        }
      } else {
        const { value } = await CapDialog.confirm({
          title: "Age Verification",
          message: `Given the nature of this platform, individuals under the age of 18 will not be permitted to participate without the appropriate written consent of the parent and/or legal guardian of the user. If you have permission or assent from the dependent to register them for the purpose of this app, you may add your dependent within the Dependents page within the application. Contact the HealthToken team at info@gethealthready.com to learn more.`,
          cancelButtonTitle: "Contact HealthToken",
          okButtonTitle: "I am over 18",
        });
        if (!value) {
          window.location.assign(
            "mailto:info@gethealthready.com?subject=Parent%20or%20guardian%20sign%20up%20for%20minor"
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!consentDate) {
      const date = moment(new Date());
      setConsentDate(date.format("MMMM Do YYYY, h:mm a"));
    }
  }, []);

  //checks to see if there are required fields
  useEffect(() => {
    if (step.page === 1) {
      required = userInfoForm.filter((field) => field.required);
      setBgColor({ backgroundColor: "#3F1F99" });
      setDisabled(false);

      //for each required field, see if it's empty
      //if so, disable button and add error message
      let updated = {};
      for (let i = 0; i < required.length; i++) {
        let first = required[i].passedValue;
        let second = "";
        let userData = userInfo[first];

        if (first && first.includes(".")) {
          const splitValue = first.split(".");
          first = splitValue[0];
          second = splitValue[1];
          userData = userInfo[first] && userInfo[first][second];
        }
        if (
          !userData ||
          (required[i].passedValue === "birthDate" &&
            !Date.parse(userInfo.birthDate))
        ) {
          setBgColor({ backgroundColor: "gainsboro" });
          setDisabled(true);
          updated = { ...updated, [required[i].passedValue]: "Required Field" };
        }
        //if field has value, remove error message
        if (
          error[required[i].passedValue] &&
          userInfo[required[i].passedValue] !== ""
        ) {
          updated = { ...updated, [required[i].passedValue]: null };
        }
      }
      setError(updated);
    } else if (step.page === 3 && userInfo) {
      setName(`${userInfo.firstName} ${userInfo.lastName}`);
    }
  }, [step, userInfo]);

  useEffect(() => {
    if (step.page === 2) {
      setBgColor({ backgroundColor: "#8F3749" });
      setDisabled(false);
    } else if (step.page === 3) {
      if (
        (name !== "" && checkedTerms && dependentView && checkedDependent) ||
        (name !== "" && checkedTerms && !dependentView)
      ) {
        setBgColor({ backgroundColor: "#3F1F99" });
        setDisabled(false);
      } else {
        setBgColor({ backgroundColor: "gainsboro" });
        setDisabled(true);
      }
    }
  }, [checkedTerms, checkedDependent, name, step, dependentView]);

  useEffect(() => {
    if (step.page === 4) {
      if (disableContinue) {
        setBgColor({ backgroundColor: "gainsboro" });
      } else {
        setBgColor({ backgroundColor: "#3F1F99" });
      }
    }
  }, [disableContinue, step]);

  useEffect(() => {
    if (!addDependentsLoading && step.page === 1 && dependentView) {
      setActiveStep(step.page + 5);
    }
  }, [addDependentsLoading]);

  const handleChange = (e, label) => {
    let value;
    if (label === "birthDate") {
      value = e;
      setSelectedDate(e);
    } else {
      value = e.target.value;
    }

    setUserInfo({ ...userInfo, [label]: value });

    let splitLabel = label.split(".");
    if (splitLabel.length === 1) {
      setUserInfo({
        ...userInfo,
        [label]: value,
      });
    }
    if (splitLabel.length === 2) {
      setUserInfo({
        ...userInfo,
        [splitLabel[0]]: {
          ...userInfo[splitLabel[0]],
          [splitLabel[1]]: value,
        },
      });
    } else if (splitLabel.length === 3) {
      setUserInfo({
        ...userInfo,
        [splitLabel[0]]: {
          ...userInfo[splitLabel[0]],
          [splitLabel[1]]: {
            ...userInfo[splitLabel[1]],
            [splitLabel[2]]: value,
          },
        },
      });
    }
  };

  const handleClick = () => {
    if (step.page === 1 && dependentView) {
      const dependentInfo = {
        birthDate: userInfo.birthDate,
        blocked: false,
        config: {
          firstTimeLogin: false,
        },
        contact: userInfo.contact,
        creator: userInfo.email,
        dependent: true,
        email: userInfo.email,
        emergencyContact: {
          address: {
            line1: userDetails.primaryAddress
              ? userDetails.primaryAddress.line1
              : "",
            line2: userDetails.primaryAddress
              ? userDetails.primaryAddress.line2
              : "",
            city: userDetails.primaryAddress
              ? userDetails.primaryAddress.city
              : "",
            country: userDetails.primaryAddress
              ? userDetails.primaryAddress.country
              : "",
            state: userDetails.primaryAddress
              ? userDetails.primaryAddress.state
              : "",
            zip: userDetails.primaryAddress
              ? userDetails.primaryAddress.zip
              : "",
          },
          contact: {
            mobileNumber: userDetails.contact
              ? userDetails.contact.mobileNumber
              : "",
          },
          email: userDetails.email,
          firstName: userDetails.firstName,
          lastName: userDetails.lastName,
          relationship: "Guardian",
        },
        ethnicity: userInfo.ethnicity,
        firstName: userInfo.firstName,
        gender: userInfo.gender,
        lastName: userInfo.lastName,
        middleName: userInfo.middleName || "",
        primaryAddress: userInfo.primaryAddress || {},
        role: "PATIENT",
      };
      handleDependentCreation(dependentInfo);
    } else if (
      (dependentView && step.page < 3) ||
      (!dependentView && step.page < 4)
    ) {
      setActiveStep(step.page + 4);
    } else if (dependentView && step.page === 3) {
      const dependent = dependents[0];
      handleConsent("termsAndCondition", dependent.id);
      handleAcceptedTc(true);
      setActiveStep(step.page + 4);
    } else if (dependentView && step.page === 4) {
      const dependent = dependents[0];
      recordsActions.updateUserRecords(onboardingRecords, dependent.id);
      closeDependentDialog();
    } else {
      recordsActions.updateUserRecords(onboardingRecords, null);
      setFirstLogin(userInfo);
      handleConsent("termsAndCondition", null);
    }
  };

  const handleContact = () => {
    window.location.assign(
      "mailto:info@gethealthready.com?subject=Parent%20or%20guardian%20sign%20up%20for%20minor"
    );
  };

  const handleFaq = () => {
    closeDependentDialog();
    history.push("/faqs");
  };

  return (
    <Grid container justifyContent="center">
      {!dependentView && (
        <Grid item xs={12}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            <Grid item>
              <IconButton
                onClick={
                  step.page === 1
                    ? () => setActiveStep(2)
                    : () => setActiveStep(step.page + 2)
                }
                aria-label={"back-button"}
              >
                <ArrowBackRoundedIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Button
                variant={"contained"}
                color={"primary"}
                onClick={logoutUserTokens}
                size={"small"}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {(step.page !== 1 || (step.page === 1 && !dependentView)) && (
        <Grid item xs={12}>
          <Typography variant="h3" className={classes.formHeader}>
            {step.page === 1 && dependentView ? null : step.header}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} className={classes.formPageContainer}>
        {step.page === 1 && (
          <FormBuilder
            userInfo={userInfo}
            form={userInfoForm}
            handleChange={handleChange}
            selectedDate={selectedDate}
            error={error}
            dependentView={dependentView}
          />
        )}
        {step.page === 2 && (
          <EmergencyForm
            step={step}
            setActiveStep={setActiveStep}
            userInfo={userInfo}
            setUserInfo={setUserInfo}
            handleChange={handleChange}
          />
        )}
        {step.page === 3 && (
          <Terms
            checkedTerms={checkedTerms}
            checkedDependent={checkedDependent}
            setCheckedTerms={setCheckedTerms}
            setCheckedDependent={setCheckedDependent}
            name={name}
            setName={setName}
            handleClick={handleClick}
            consentDate={consentDate}
            type={"onboarding"}
            dependentView={dependentView}
          />
        )}
        {step.page === 4 && (
          <div style={{ padding: 20 }}>
            <Records
              editing={true}
              type={"onboarding"}
              dependentView={dependentView}
              setOnboardingRecords={setOnboardingRecords}
              setDisableContinue={setDisableContinue}
            />
          </div>
        )}
      </Grid>
      <Grid item xs={12} className={classes.formButtonContainer}>
        <Button
          className={classes.formButton}
          style={bgColor}
          onClick={handleClick}
          disabled={step.page === 4 ? disableContinue : disabled}
          key={`${disabled}`}
          fullWidth
        >
          {step.button}
        </Button>
        {addDependentsLoading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </Grid>
      <Dialog
        open={openConfirmationDialog}
        onClose={() => setOpenConfirmationDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dependentView ? "Consent Verification" : "Age Verification"}
        </DialogTitle>
        <DialogContent>
          {dependentView ? (
            <DialogContentText id="alert-dialog-description">
              You must have permission or assent from the dependent to register
              them for the purpose of this app. Refer to the FAQs to learn more.
            </DialogContentText>
          ) : (
            <DialogContentText id="alert-dialog-description">
              Given the nature of this platform, individuals under the age of 18
              will not be permitted to participate without the appropriate
              written consent of the parent and/or legal guardian of the user.
              If you have permission or assent from the dependent to register
              them for the purpose of this app, you may add your dependent
              within the Dependents page within the application. Contact the
              HealthToken team at info@HealthToken.com to learn more.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={dependentView ? handleFaq : handleContact}
            color="secondary"
            variant={"contained"}
          >
            {dependentView ? "View FAQs" : "Contact HealthReady"}
          </Button>
          <Button
            onClick={() => setOpenConfirmationDialog(false)}
            color="primary"
            autoFocus
            variant={"contained"}
          >
            {dependentView ? "I have consent" : "I am over 18"}
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default withStyles(OnboardingStyles)(OnboardingPage);
