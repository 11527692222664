import React, { useContext, lazy, Suspense } from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "./components/Shared/PrivateRoute";
import { AppContext } from "./App";
import TrialsView from "./views/TrialsView";

// const TrialsView = lazy(() => import("./views/TrialsView"));
const RecordsView = lazy(() => import("./views/RecordsView"));
const PaymentsView = lazy(() => import("./views/PaymentsView"));
const FaqView = lazy(() => import("./views/FaqView"));
const ContactView = lazy(() => import("./views/ContactView"));
const ProfileView = lazy(() => import("./views/ProfileView"));
const TermsView = lazy(() => import("./views/TermsView"));
const HomeView = lazy(() => import("./views/HomeView"));
const DependentsView = lazy(() => import("./views/DependentsView"));

const Routes = (props) => {
  const { firstLogin } = props;
  const { userDetails } = useContext(AppContext);

  return (
    <Suspense fallback={null}>
      <Switch>
        <PrivateRoute
          exact
          path="/clinical-research"
          component={(props) => <TrialsView {...props} />}
        />
        <PrivateRoute
          exact
          path="/clinical-research/:id"
          component={(props) => <TrialsView {...props} type={"details"} />}
        />
        <PrivateRoute
          exact
          path="/records"
          component={(props) => <RecordsView {...props} />}
        />
        <PrivateRoute
          exact
          path="/records/:id"
          component={(props) => <RecordsView {...props} type={"details"} />}
        />
        <PrivateRoute
          exact
          path="/rewards"
          component={(props) => <PaymentsView {...props} />}
        />
        <PrivateRoute
          exact
          path="/rewards/:id"
          component={(props) => <PaymentsView {...props} type={"details"} />}
        />
        <PrivateRoute
          exact
          path="/faqs"
          component={(props) => <FaqView {...props} />}
        />
        <PrivateRoute
          exact
          path="/contact"
          component={(props) => <ContactView {...props} />}
        />
        <PrivateRoute
          exact
          path="/profile"
          component={(props) => <ProfileView {...props} />}
        />
        <PrivateRoute
          exact
          path="/settings"
          component={(props) => <ProfileView {...props} />}
        />
        <PrivateRoute
          exact
          path="/terms"
          component={(props) => <TermsView {...props} />}
        />
        <PrivateRoute
          exact
          path="/dependents"
          component={(props) => <DependentsView {...props} />}
        />
        <PrivateRoute
          path="*"
          component={(props) => (
            <HomeView {...props} userDetails={userDetails} />
          )}
        />
      </Switch>
    </Suspense>
  );
};

export default Routes;
