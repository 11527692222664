const InputStyles = (theme) => ({
  searchBar: {
    width: "100%",
    // margin: "8vw 5vw 0",
  },
  searchPopper: {
    width: "100%",
    // maxWidth: "330px",
    // marginLeft: "5vw",
    overflow: "auto",
  },
  paper: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "300",
    margin: "15px 5px 5px 5px",
    backgroundColor: "#EBEBEB",
    [theme.breakpoints.up("md")]: {
      width: "95%",
      margin: "40px 20px 20px 20px",
    },
  },
  searchInput: {
    "& input": {
      paddingLeft: "10px",
      // position: "fixed",
      width: "100%",
    },
  },
  searchIcon: {
    marginInlineStart: "auto",
  },
});

export default InputStyles;
