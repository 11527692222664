const SharedStyles = (theme) => ({
  sharedMain: {
    width: "100%",
    marginTop: -50,
  },
  sharedPageContainer: {
    overflowY: "auto",
    paddingBottom: "15px",
  },
  sharedButtonContainer: {
    textAlign: "center",
    // position: "fixed",
    paddingBottom: "4.5vh",
    left: "12vw",
    [theme.breakpoints.up("md")]: {
      left: "35vw",
    },
  },
  sharedButton: {
    backgroundColor: "#3F1F99",
    color: "#ffffff",
    borderRadius: "0px",
    padding: "15px 30px 15px 30px",
    fontWeight: "bold",
    marginTop: "10vw",
    width: "78vw",
    [theme.breakpoints.up("md")]: {
      width: "30vw",
    },
  },
  sharedCarousel: {
    "& button": {
      backgroundColor: "#C4C4C4",
      width: "23px",
      height: "8px",
      borderRadius: "50px",
      boxShadow: "none",
    },
    "& .rec-dot_active": {
      backgroundColor: "#3F1F99",
      width: "23px",
      height: "8px",
      borderRadius: "50px",
      boxShadow: "none",
    },
    "& :focus": {
      outline: "none",
    },
  },
  scrollCard: {
    padding: "15px 5px",
    textAlign: "center",
    margin: "0 5px 5px 5px",
    width: "100%",
    "& #avatar": {
      margin: "5px auto",
    },
    "& p.giant": {
      fontSize: "5.75vw",
      color: "#0b0b0b",
      margin: "0 auto",
      fontWeight: "700",
    },
    "& p.large": {
      fontSize: "4.4vw",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0 auto",
    },
    "& p.medium": {
      fontSize: "4vw",
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 auto",
    },
    "& p.small": {
      fontSize: "3.5vw",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0 auto",
    },
  },
  title: {
    fontSize: "20px",
    color: "#0b0b0b",
    borderBottom: "2px #0b0b0b solid",
    // width: "55vw",
    paddingBottom: "5px",
  },
  titleContainer: {
    position: "fixed",
    zIndex: "1",
    backgroundColor: "#ffffff",
  },
  container: {
    height: "inherit",
    [theme.breakpoints.up("md")]: {
      padding: "5vw 20vw 0 20vw",
    },
  },
  cardsContainer: {
    overflow: "auto",
  },
  cardContainer: {
    right: 24,
    paddingTop: 75,
    overflow: "auto",
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordion-root:before": {
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "& .MuiAccordion-root": {
      transition: "margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: theme.palette.text.primary,
  },
  listItems: {
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#fff",
    },
  },
});

export default SharedStyles;
