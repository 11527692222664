import React from "react";

//components
import { IconButton, InputBase, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import filterIcon from "../../assets/img/filter_icon.svg";
import { withStyles } from "@material-ui/core/styles";
import InputStyles from "../../assets/jss/components/InputStyles";

const Search = (props) => {
  const {
    classes,
    searchValue,
    placeholder,
    handleChange,
    handleClearSearch,
    desktopFilter,
    setFilterOpen,
    filterOpen,
  } = props;

  return (
    <Paper
      component="form"
      className={classes.paper}
      elevation={0}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <InputBase
        id="searchBar"
        value={searchValue}
        className={classes.searchInput}
        placeholder={placeholder}
        inputProps={{
          "aria-label": placeholder,
          autoComplete: "off",
          "data-testid": "searchbar",
        }}
        onChange={handleChange}
        fullWidth
      />
      {searchValue.length < 1 ? (
        <IconButton disabled className={classes.searchIcon} aria-label="search">
          <SearchIcon />
        </IconButton>
      ) : null}
      {searchValue.length > 0 ? (
        <IconButton
          className={classes.searchIcon}
          aria-label="closeSearch"
          onClick={handleClearSearch}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {desktopFilter && (
        <IconButton
          onClick={() => setFilterOpen(!filterOpen)}
          style={{ marginInlineStart: "auto", color: "#0b0b0b" }}
          aria-label={"filterIcon"}
        >
          <img src={filterIcon} alt="Filter icon" />
        </IconButton>
      )}
    </Paper>
  );
};

export default withStyles(InputStyles)(Search);
