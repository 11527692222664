import React, { useContext } from "react";
import PropTypes from "prop-types";
import * as dependentActions from "../Dependents/DependentsActions";
import { useDispatch } from "react-redux";

//redux additions
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as confirmActions from "../Confirmation/ConfirmationActions";

//material-ui components
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import Typography from "@material-ui/core/Typography";
import { deleteDependent } from "../Dependents/DependentsActions";
import * as userActions from "../User/UserActions";
import { AppContext } from "../../App";

//actions taken upon confirmation/cancellation of an action
export const ConfirmationDialog = (props) => {
  const { confirmation, actions } = props;
  const { userDetails } = useContext(AppContext);
  const dispatch = useDispatch();

  //cancelling an action event response
  const handleCancel = (event) => {
    if (event) {
      event.preventDefault();
    }
    actions.hideConfirmation();
  };

  //confirming an action event response
  const handleOk = (event) => {
    if (event) {
      event.preventDefault();
    }
    if (confirmation.confirmationType === "deleteDependent") {
      dispatch(dependentActions?.deleteDependent(confirmation.id));
    }
    if (confirmation.confirmationType === "removeClinicalTrial") {
      dispatch(
        userActions?.removeClinicalTrial(
          confirmation.id,
          userDetails.dependant ? userDetails.id : null
        )
      );
    }
    if (confirmation.confirmationType === "removeRecordItem") {
      confirmation.id(true);
    }
    actions.hideConfirmation();
  };

  return (
    <Dialog
      open={confirmation.visible}
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
    >
      <DialogTitle id="confirmation-dialog-title">
        {confirmation.title}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{confirmation.message}</Typography>
      </DialogContent>
      <DialogActions>
        <div>
          <Button
            onClick={handleCancel}
            color="primary"
            style={{ marginRight: 8 }}
          >
            Cancel
          </Button>
          <Button variant={"contained"} onClick={handleOk} color="primary">
            Ok
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  confirmation: PropTypes.object,
};

ConfirmationDialog.defaultProps = {
  confirmation: {
    title: null,
    message: null,
    confirmationType: null,
    visible: false,
  },
};

const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
});

export function mapStateToProps(state) {
  return {
    ...state,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(confirmActions, dispatch),
  };
}

//middleware
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(ConfirmationDialog));
