const OnboardingStyles = (theme) => ({
  dotActive: {
    width: "35px",
    borderRadius: "10px",
  },
  dotActiveAlternate: {
    width: "35px",
    borderRadius: "10px",
    backgroundColor: "white",
  },
  dot: {
    marginLeft: "5px",
    marginRight: "5px",
  },
  dots: {
    margin: "auto",
    // paddingLeft: "16vw",
  },
  root: {
    background: "none",
    "& button": {
      color: "#ffffff",
    },
  },
  mobileStepper: {
    minHeight: "30px",
    [theme.breakpoints.up("sm")]: {
      minHeight: "50px",
      "& button": {
        fontSize: "2.5vw",
        paddingRight: "2vw",
      },
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "70px",
      "& button": {
        fontSize: "1.5vw",
        paddingRight: "3vw",
      },
    },
  },
  aboutContainer: {
    textAlign: "center",
    padding: "0 10vw",
    height: "100vh",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      padding: "0 18vw",
      backgroundPositionY: "center",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 21vw",
      backgroundPositionY: "center",
    },
  },
  aboutContainer2: {
    textAlign: "center",
    padding: "0 10vw",
    height: "100vh",
    backgroundPositionY: "20vw",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [theme.breakpoints.up("sm")]: {
      padding: "0 18vw",
      backgroundPositionY: "center",
    },
    [theme.breakpoints.up("md")]: {
      padding: "0 21vw",
      backgroundPositionY: "center",
    },
  },
  aboutLogoContainer: {
    width: "45vw",
    height: "45vw",
    borderRadius: "50%",
    margin: "auto",
    backgroundColor: "#cecece",
    marginBottom: "12vh",
    position: "relative",
    top: "8vh",
    [theme.breakpoints.up("sm")]: {
      width: "30vw",
      height: "30vw",
      marginBottom: "24vh",
    },
    [theme.breakpoints.up("md")]: {
      width: "25vw",
      height: "25vw",
      maxHeight: "175px",
      maxWidth: "175px",
      marginBottom: "20vh",
    },
  },
  aboutLogo: {
    width: "26vw",
    paddingTop: "11vw",
    [theme.breakpoints.up("sm")]: {
      width: "18vw",
      paddingTop: "8vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "15vw",
      paddingTop: "40px",
      maxWidth: "120px",
    },
  },
  aboutHeader: {
    margin: "0 0 3vw 0",
    fontSize: "9vw",
    color: "#ffffff",
    [theme.breakpoints.up("sm")]: {
      margin: "0 0 2vw 0",
      fontSize: "7vw",
      paddingLeft: "3vw",
    },
    [theme.breakpoints.up("md")]: {
      margin: "0 0 1.5vw 0",
      fontSize: "2.5vw",
    },
  },
  aboutText: {
    textAlign: "left",
    "& p": {
      fontSize: "3.5vw",
      color: "#ffffff",
      [theme.breakpoints.up("sm")]: {
        fontSize: "3.5vw",
        paddingLeft: "3vw",
      },
      [theme.breakpoints.up("md")]: {
        fontSize: "1.5vw",
      },
    },
  },
  aboutButton: {
    backgroundColor: "#3F1F99",
    color: "#ffffff",
    borderRadius: "0px",
    padding: "15px 30px 15px 30px",
    fontWeight: "bold",
    marginTop: "10vw",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0",
      right: "10%",
      width: "120%",
      fontSize: "2.5vw",
    },
    [theme.breakpoints.up("md")]: {
      marginTop: "100px",
      right: "10%",
      width: "100s%",
      fontSize: "1vw",
    },
  },
  formContainer: {
    paddingBottom: 70,
    paddingLeft: "5vw",
    paddingRight: "5vw",
    position: "relative",
    // maxHeight: "74vh",
    [theme.breakpoints.up("md")]: {
      padding: "5px 20vw 5vh 20vw",
    },
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
    color: "#fff",
  },
  formBackButton: {
    position: "fixed",
    top: "3.25vh",
    left: "5vw",
  },
  formHeader: {
    margin: "3.5vh auto 3vh auto",
    fontSize: "5.5vw",
    fontWeight: "bold",
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      fontSize: "2vw",
    },
  },
  swipeableChildren: {
    overflow: "auto !important",
  },
  formPageContainer: {
    maxHeight: "74vh",
    overflowY: "auto",
    marginBottom: 20,
  },
  formInputLarge: {
    marginTop: "20px",
  },
  formDOBInput: {
    marginTop: "20px",
  },
  formButtonContainer: {
    zIndex: 1000,
    textAlign: "center",
    position: "fixed",
    backgroundColor: "#fff",
    height: 60,
    width: "100vw",
    paddingTop: 5,
    bottom: 5,
  },
  formButton: {
    backgroundColor: "#3F1F99",
    color: "#ffffff",
    padding: "15px 30px 15px 30px",
    fontWeight: "bold",
    width: "78vw",
    [theme.breakpoints.up("md")]: {
      width: "30vw",
    },
  },
  formBanner: {
    color: "#ffffff",
    height: "100%",
    width: "100vw",
    padding: "10vw",
    "& h1": {
      fontSize: "9vw",
      fontWeight: "bold",
    },
    "& p": {
      paddingTop: "5vw",
    },
    "& img": {
      width: "70vw",
    },
    [theme.breakpoints.up("md")]: {
      padding: "4vw 10vw",
      "& h1": {
        fontSize: "2vw",
      },
      "& p": {
        paddingTop: "1vw",
      },
      "& img": {
        width: "10vw",
      },
    },
  },
  formSkipText: {
    textAlign: "center",
    paddingTop: "20px",
    marginBottom: 20,
    "& a": {
      textDecoration: "none",
      color: "rgba(0, 0, 0, 0.38)",
    },
  },
  emergencyContainer: {
    marginTop: "3.5vh",
    padding: "10px 5vw 0 5vw",
    [theme.breakpoints.up("md")]: {
      padding: "10px 20vw 0 20vw",
    },
  },
  consentContainer: {
    padding: "10vw 10vw 25px 10vw",
    [theme.breakpoints.up("md")]: {
      padding: "5vw 20vw 0 20vw",
      paddingBottom: 20,
    },
  },
  consentBody: {
    paddingTop: "5vw",
    "& p": {
      fontSize: "4.5vw",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "3vw",
      "& p": {
        fontSize: "1vw",
      },
    },
  },
  consentCheck: {
    padding: "10vw 0 15px 0",
    [theme.breakpoints.up("md")]: {
      paddingTop: "15px",
    },
  },
  consentAction: {
    padding: "10vw 0 25px 0",
    [theme.breakpoints.up("md")]: {
      padding: "5vw 0 0 0",
    },
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default OnboardingStyles;
