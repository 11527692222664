import * as types from "./FaqActions";

//reducer for shared actions
export const faqs = (state = [], action) => {
  switch (action.type) {
    case types.GET_FAQ_REQUEST:
      return {
        ...state,
        faqsLoading: true,
        error: null,
      };

    case types.GET_FAQ_SUCCESS:
      return {
        ...state,
        items: action.payload,
        faqsLoading: false,
      };

    case types.GET_FAQ_FAILURE:
      return {
        ...state,
        error: action.error,
        faqsLoading: false,
      };

    default:
      return state;
  }
};
