import { states } from "../helpers/states";

export const userInfoForm = [
  {
    name: "text",
    label: "First Name",
    style: { marginTop: "5px" },
    className: false,
    passedValue: "firstName",
    required: true,
  },
  {
    name: "text",
    label: "Last Name",
    className: true,
    passedValue: "lastName",
    required: true,
  },
  {
    name: "date",
    label: "Date of Birth",
    passedValue: "birthDate",
    required: true,
    gridSize: 12,
  },
  {
    name: "dropdown",
    label: "Gender",
    passedValue: "gender",
    required: true,
    className: true,
    gridSize: 12,
    options: [
      { value: "MALE", label: "Male" },
      { value: "FEMALE", label: "Female" },
      { value: "OTHER", label: "Other" },
      { value: "INTERSEX", label: "Intersex" },
    ],
  },
  {
    name: "dropdown",
    label: "Ethnicity",
    passedValue: "ethnicity",
    required: true,
    className: true,
    gridSize: 12,
    helperText:
      "Select the option which best represents your racial or ethnic heritage",
    options: [
      { value: "White Non-Hispanic", label: "White Non-Hispanic" },
      { value: "White Hispanic or Latino", label: "White Hispanic or Latino" },
      {
        value: "Black, Afro-Caribbean, or African American",
        label: "Black, Afro-Caribbean, or African American",
      },
      {
        value: "Asian (Eastern, Southeastern, Middle Eastern)",
        label: "Asian (Eastern, Southeastern, Middle Eastern)",
      },
      { value: "Native American", label: "Native American" },
      {
        value: "Prefer not to answer this question",
        label: "Prefer not to answer this question",
      },
    ],
  },
  {
    name: "text",
    label: "Phone Number",
    type: "tel",
    mask: "(999) 999-9999",
    className: true,
    passedValue: "contact.mobileNumber",
  },
  {
    name: "text",
    label: "Address Line 1",
    className: true,
    passedValue: "primaryAddress.line1",
  },
  {
    name: "text",
    label: "Address Line 2",
    className: true,
    passedValue: "primaryAddress.line2",
  },
  {
    name: "text",
    label: "City",
    className: true,
    passedValue: "primaryAddress.city",
    required: true,
  },
  {
    name: "dropdown",
    label: "State",
    className: true,
    passedValue: "primaryAddress.state",
    gridSize: 6,
    style: { width: "96%" },
    options: states,
    required: true,
  },
  {
    name: "text",
    label: "Zip Code",
    mask: "99999",
    passedValue: "primaryAddress.zip",
    className: true,
    style: { width: "96%", float: "right" },
    gridSize: 6,
    required: true,
  },
];
