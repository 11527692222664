import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import Sidebar from "../Sidebar/Sidebar";
import { AppContext } from "../../App";

const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: {
    height: 64,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    maxWidth: "80%",
  },
}));

export default function DesktopNavBar({ children }) {
  const classes = useStyles();
  const { logoutUserTokens } = useContext(AppContext);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar />
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        anchor={"left"}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        {/*<div className={classes.toolbar} />*/}
        <div
          aria-label="drawer"
          tabIndex={0}
          role="button"
          style={{ height: "100%" }}
        >
          <Sidebar logoutUserTokens={logoutUserTokens} />
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
}
