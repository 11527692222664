const NavBarStyles = (theme) => ({
  overRideShadow: {
    boxShadow: "none",
    backgroundColor: "#fff",
    maxHeight: 114,
  },
  menuButton: {
    margin: "10px 5px 10px -5px",
  },
  menuHeader: {
    fontStyle: "italic",
    fontWeight: "bold !important",
    color: "#0b0b0b",
    fontSize: "25px !important",
    fontFamily: "Avenir Next",
  },
});

export default NavBarStyles;
