import { combineReducers } from "redux";
import notification from "../../Notifications/NotificationsReducers";
import confirmation from "../../Confirmation/ConfirmationReducers";
import { faqs } from "../../Faq/FaqReducer";
import { user } from "../../User/UserReducer";
import { trials } from "../../Trials/TrialsReducer";
import { payments, balance } from "../../Payments/PaymentsReducer";
import { metrics } from "../../Home/MetricsReducer";
import { records } from "../../Records/RecordsReducer";
import { dependents } from "../../Dependents/DependentsReducer";

//Root Reducer
const index = combineReducers({
  notification,
  confirmation,
  user,
  faqs,
  trials,
  payments,
  metrics,
  records,
  dependents,
  balance,
});

export default index;
