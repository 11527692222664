export const statusList = [
  "recruiting",
  "withdrawn",
  "enrolling by invitation",
  "temporarily not available",
  "terminated",
  "withheld",
  "active, not recruiting",
  "not yet recruiting",
  "no longer available",
  "unknown status",
  "approved for marketing",
  "completed",
  "available",
  "suspended",
];
